import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { SortOrder } from "antd/es/table/interface";

const OfferCodeTableCols = (handleDelete: any, handleUpdate: any) => [
  {
    title: "Code",
    dataIndex: "code",
    key: "code",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Code Status",
    dataIndex: "code_status",
    key: "code_status",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Offer Id",
    dataIndex: "offer_id",
    key: "offer_id",
    render: (value: any) => {
      return <p>{value}</p>;
    },
    defaultSortOrder: "ascend" as SortOrder,
    sorter: (a: any, b: any) => a.offer_id - b.offer_id,
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <DeleteOutlined
            className="text-[red]"
            onClick={() => handleDelete(value)}
          />
          <EditOutlined
            className="text-blue-600"
            onClick={() => handleUpdate(value)}
          />
        </div>
      );
    },
  },
];

export default OfferCodeTableCols;
