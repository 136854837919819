import
{
    GET_CLASSIFICATION_LOADING,
    GET_CLASSIFICATION_SUCCESS,
    GET_CLASSIFICATION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const listSingleClassificationsAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: GET_CLASSIFICATION_LOADING, payload: true});
        const response = await listSingleItemService('/api/stlr/classification/', id);
        dispatch({type: GET_CLASSIFICATION_SUCCESS, payload: response.data});
    } catch (err) { 
        dispatch({type: GET_CLASSIFICATION_FAILURE, payload: err});
    } finally {
        dispatch({type: GET_CLASSIFICATION_LOADING, payload: false});
    }
}