import { useFormik } from "formik";
import * as yup from "yup";
import CreateUserModel from "models/CreateUserModel";
import { Input, Select } from "antd";
import { useDropdown } from "hooks/useDropdown";
import { useDispatch } from "react-redux";
import { createUserAction } from "redux/actions/userActions/createUserAction";
import { useNavigate } from "react-router";
import Loader from "components/Loader/Loader";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useState } from "react";

const CreateUser = () => {
  const { rolesDropdown, merchantsDropdown, channelsDropdown } = useDropdown();

  const dispatch: any = useDispatch();

  const { loading } = useSelector((state: RootState) => state.createUserReducer);

  const [adminType, setAdminType] = useState("");

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      username: "",
      role: "",
      channel_id: "",
      merchant_id: "",
    },
    validationSchema: yup.object({
      name: yup.string().min(2).required("please enter this field"),
      email: yup
        .string()
        .email("please provide a valid email")
        .required("please enter this field"),
      password: yup.string().min(8).required("please enter this field"),
      username: yup.string().required("please enter this field"),
      role: yup.string().required("please enter this field"),
      merchant_id: yup.string().when(
        'role', ([role], sch) => {
          return role === "MERCHANT_ADMIN" ? sch.required('please enter this field') : sch.optional()
        }
      ),
      channel_id: yup.string().when(
        'role', ([role], sch) => {
          return role === "CHANNEL_ADMIN" ? sch.required('please enter this field') : sch.optional()
        }
      )
    }),
    onSubmit: async (values: CreateUserModel) => {
      await dispatch(
        createUserAction(
          {
            name: values.name,
            email: values.email,
            password: values.password,
            username: values.username,
            role: values.role,
            channel_id: values.channel_id,
            merchant_id: values.merchant_id
          },
          () => navigate(-1)
        )
      );
    },
  });

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Create User</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Name*</label>
              <Input
                placeholder="Name"
                name="name"
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <p className="text-[red]">{formik.errors.name}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Email*</label>
              <Input
                placeholder="Email"
                name="email"
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.email && (
                <p className="text-[red]">{formik.errors.email}</p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Password*</label>
              <Input
                placeholder="Password"
                name="password"
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.password && (
                <p className="text-[red]">{formik.errors.password}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Username*</label>
              <Input
                placeholder="Username"
                name="username"
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.username && (
                <p className="text-[red]">{formik.errors.username}</p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col items-start">
            <label>Role*</label>
            <Select
              options={rolesDropdown}
              onSelect={(value) => {
                setAdminType(value);
                formik.setFieldValue("role", value);
              }}
              className="w-full"
              placeholder="Role"
            />
          </div>
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Channel</label>
              <Select
                placeholder="Channel"
                className="!h-[2.5rem] w-full"
                options={channelsDropdown}
                disabled={adminType !== "CHANNEL_ADMIN"}
                onSelect={(value: string) => formik.setFieldValue('channel_id', value)}
              />
              {formik.errors.channel_id && (
                <p className="text-[red]">{formik.errors.channel_id}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Merchant</label>
              <Select
                placeholder="Merchant"
                className="!h-[2.5rem] w-full"
                options={merchantsDropdown}
                disabled={adminType !== "MERCHANT_ADMIN"}
                onSelect={(value: string) => formik.setFieldValue('merchant_id', value)}
              />
              {formik.errors.merchant_id && (
                <p className="text-[red]">{formik.errors.merchant_id}</p>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {loading ? <Loader /> : <p className="text-white font-bold">Create</p>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateUser;
