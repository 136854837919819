import { useEffect, FC } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { listMerchantsAction } from "redux/actions/merchantsActions/listMerchantsAction";
import { RootState } from "redux/rootReducer";
import { listChannelsAction } from "redux/actions/channelsActions/listChannelsAction";
import { listCategoriesAction } from "redux/actions/categoriesActions/listCategoriesAction";
import { listClassificationsAction } from "redux/actions/classificationsActions/listClassificationsAction";
import { listOffersAction } from "redux/actions/offersActions/listOffersAction";

const useDropdown = () => {

    const dispatch: any = useDispatch();

    const { user } = useSelector((state: RootState) => state.loginReducer);

    const { merchants } = useSelector((state: RootState) => state.listMerchantsReducer);
    const { channels } = useSelector((state: RootState) => state.listChannelsReducer);
    const { categories } = useSelector((state: RootState) => state.listCategoriesReducer);
    const { classifications } = useSelector((state: RootState) => state.listClassificationsReducer);
    const { offers } = useSelector((state: RootState) => state.listOffersReducer);

    const rolesDropdown = ["ADMIN", "MERCHANT_ADMIN", "CHANNEL_ADMIN", "MERCHANT_AGENT"].map((item: string) => {
        return {
            label: item,
            value: item
        }
    });

    const discountTypeDropdown = ["PERCENTAGE", "VALUE"].map((item: string) => {
        return {
            label: item,
            value: item
        }
    });

    const limitTypeDropdown = ["QUOTA", "DURATION", "BOTH", "NONE"].map((item: string) => {
        return {
            label: item,
            value: item
        }
    });

    const statusDropdown = ["PENDING", "ACTIVE", "CLOSED"].map((item: string) => {
        return {
            label: item,
            value: item
        }
    });

    const offerCodeStatusDropdown = ["AVAILABLE", "PENDING", "REDEEMED"].map((item: string) => {
        return {
            label: item,
            value: item
        }
    });

    const merchantsDropdown = merchants?.map((item: any) => {
        return {
            label: item.name,
            value: item.id
        }
    });

    const channelsDropdown = channels?.map((item: any) => {
        return {
            label: item.name,
            value: item.id
        }
    });

    const categoriesDropdown = categories?.map((item: any) => {
        return {
            label: item.name,
            value: item.id
        }
    });

    const classificationsDropdown = classifications?.map((item: any) => {
        return {
            label: item.name,
            value: item.id
        }
    });


    const offersDropdown = offers?.map((item: any) => {
        return {
            label: item.id,
            value: item.id
        }
    });

    const voucherTypesDropdown = ["PERCENTAGE", "VALUE"].map((item: string) => {
        return {
            label: item,
            value: item
        }
    });

    const voucherStatusDropdown = ["PENDING", "ACTIVE", "CLOSED"].map((item: string) => {
        return {
            label: item,
            value: item
        }
    });

    useEffect(() => {
        if (user.role === "ADMIN") {
            dispatch(listMerchantsAction());
            dispatch(listChannelsAction());
            dispatch(listCategoriesAction());
            dispatch(listClassificationsAction());
            dispatch(listOffersAction(""));
        }
    }, []);

    return {
        rolesDropdown,
        merchantsDropdown,
        channelsDropdown,
        categoriesDropdown,
        classificationsDropdown,
        discountTypeDropdown,
        limitTypeDropdown,
        statusDropdown,
        offersDropdown,
        offerCodeStatusDropdown,
        voucherTypesDropdown,
        voucherStatusDropdown
    }
}

export { useDropdown };