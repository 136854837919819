import
{
    GET_USER_LOADING,
    GET_USER_SUCCESS, 
    GET_USER_FAILURE
}
from "redux/types";

const initialState = {
    userLoading: false,
    user: null,
    errors: null
}

export const getSingleUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_USER_LOADING :
            return {...state, userLoading: action.payload};
        case GET_USER_SUCCESS :
            return {...state, user: action.payload.data};
        case GET_USER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}