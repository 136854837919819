import
{
    LIST_USERS_LOADING,
    LIST_USERS_SUCCESS,
    LIST_USERS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listUsersAction = (params?: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_USERS_LOADING, payload: true});
        const response = await listItemsService('/api/stlr/user', params);
        dispatch({type: LIST_USERS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_USERS_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_USERS_LOADING, payload: false});
    }
}