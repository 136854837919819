import
{
    LIST_CHANNEL_MERCHANTS_LOADING,
    LIST_CHANNEL_MERCHANTS_SUCCESS,
    LIST_CHANNEL_MERCHANTS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const listChannelMerchantsAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_CHANNEL_MERCHANTS_LOADING, payload: true});
        const response = await listSingleItemService('/api/stlr/channelMerchant/showAllChannelsMerchants/', id);
        dispatch({type: LIST_CHANNEL_MERCHANTS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_CHANNEL_MERCHANTS_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_CHANNEL_MERCHANTS_LOADING, payload: false});
    }
}