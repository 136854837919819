import
{
    UPLOAD_OFFER_CODES_LOADING,
    UPLOAD_OFFER_CODES_SUCCESS,
    UPLOAD_OFFER_CODES_FAILURE
}
from "redux/types";

const initialState = {
    uploadLoading: false,
    success: null,
    errors: null
}

export const uploadOfferCodesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPLOAD_OFFER_CODES_LOADING :
            return {...state, uploadLoading: action.payload};
        case UPLOAD_OFFER_CODES_SUCCESS :
            return {...state, success: action.payload.success};
        case UPLOAD_OFFER_CODES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
} 