import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Modal, Table, Tabs, TabsProps, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/rootReducer";
import {
  CREATE_CATEGORY_PATH,
  CREATE_MERCHANT_PATH,
  CREATE_SUB_CATEGORY_PATH,
} from "Routes/paths";
import { useResponsive } from "hooks/useResponsive";
import MerchantTableCols from "models/tableColumns/MerchantTableCols";
import { listMerchantsAction } from "redux/actions/merchantsActions/listMerchantsAction";
import { deleteMerchantAction } from "redux/actions/merchantsActions/deleteMerchantAction";
import { listSingleMerchantAction } from "redux/actions/merchantsActions/listSingleMerchantAction";
import { listCategoriesAction } from "redux/actions/categoriesActions/listCategoriesAction";
import CategoryTableCols from "models/tableColumns/CategoryTableCols";
import { listSingleCategoryAction } from "redux/actions/categoriesActions/listSingleCategoryAction";
import { deleteCategoryAction } from "redux/actions/categoriesActions/deleteCategoryAction";
import SubCategoryTableCols from "models/tableColumns/SubCategoryTableCols";
import { listSubCategoriesAction } from "redux/actions/subCategoriesActions/listSubCategoriesAction";
import { listSingleSubCategoryAction } from "redux/actions/subCategoriesActions/listSingleSubCategoryAction";
import { deleteSubCategoryAction } from "redux/actions/subCategoriesActions/deleteSubCategoryAction";
import { listSingleChannelAction } from "redux/actions/channelsActions/listSingleChannelAction";

const Merchants = () => {
  const { user } = useSelector((state: RootState) => state.loginReducer);

  const { merchantsLoading, merchants } = useSelector(
    (state: RootState) => state.listMerchantsReducer
  );
  const { deleteLoading } = useSelector(
    (state: RootState) => state.deleteMerchantReducer
  );
  const { merchantLoading } = useSelector(
    (state: RootState) => state.listSingleMerchantReducer
  );

  const { categoriesLoading, categories } = useSelector(
    (state: RootState) => state.listCategoriesReducer
  );
  const { deleteCategoryLoading } = useSelector(
    (state: RootState) => state.deleteCategoryReducer
  );
  const { categoryLoading } = useSelector(
    (state: RootState) => state.listSingleCategoryReducer
  );

  const { subCategoriesLoading, subCategories } = useSelector(
    (state: RootState) => state.listSubCategoriesReducer
  );
  const { subCategoryLoading } = useSelector(
    (state: RootState) => state.listSingleSubCategoryReducer
  );
  const { deleteSubCategoryLoading } = useSelector(
    (state: RootState) => state.deleteSubCategoryReducer
  );

  const { channel } = useSelector(
    (state: RootState) => state.listSingleChannelReducer
  );

  const [modalOpen, setModalOpen] = useState(false);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [image, setImage] = useState("");

  const [deletingId, setDeletingId] = useState("");

  const [search, setSearch] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("merchants");

  const { isMax1110 } = useResponsive();

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const adminItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Merchants",
      children: <></>,
    },
    {
      key: "2",
      label: "Categories",
      children: <></>,
    },
    {
      key: "3",
      label: "Sub Categories",
      children: <></>,
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Merchants",
      children: <></>,
    },
  ];

  const uniqueClassifications: { [name: string]: boolean } = {};

  // Step 2: Iterate through subCategories and populate uniqueCategories
  channel?.subClassifications?.forEach((subClassification: any) => {
    const classificationName = subClassification.classification.name;
    uniqueClassifications[classificationName] = true;
  });

  // Step 3: Extract unique category names from uniqueCategories object
  const uniqueClassificationNames = Object.keys(uniqueClassifications);

  useEffect(() => {
    dispatch(listMerchantsAction());
    user?.role === "CHANNEL_ADMIN" &&
      dispatch(listSingleChannelAction(user?.channel?.uuid));
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (user?.role !== "ADMIN" && user?.role !== "CHANNEL_ADMIN") {
      dispatch({ type: "LOG_OUT" });
    }
  }, [user]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-3">
      {isMax1110 && (
        <div
          onClick={() => {
            if (selectedCategory === "merchants") {
              navigate(CREATE_MERCHANT_PATH);
            } else if (selectedCategory === "categories") {
              navigate(CREATE_CATEGORY_PATH);
            }
          }}
          className="w-[3rem] h-[3rem] absolute bottom-10 right-10 bg-[--primary] rounded-full flex items-center justify-center z-10"
        >
          <PlusOutlined className="text-white text-2xl" />
        </div>
      )}

      {isMax1110 && (
        <Input
          prefix={<SearchOutlined />}
          onChange={(e: any) => setSearch(e.target.value)}
          className="!h-[2.5rem] !w-[90%]"
          placeholder="Search by id, name, or email"
        />
      )}

      <div className="w-[90%] flex flex-col items-start">
        {user?.role === "CHANNEL_ADMIN" && (
          <>
            <div className="w-full flex items-center justify-start gap-x-4">
              <h1 className="text-4xl font-bold">{channel?.name}</h1>
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}/public/uploads/channelLogos/${channel?.logo}`}
                className="w-[4rem] h-[4rem] rounded-full object-cover"
                alt="logo"
              />
            </div>
            <p className="font-bold text-xl ml-5">Classifications</p>
            <div className="flex flex-row items-center gap-x-2 ml-5 flex-wrap">
              {uniqueClassificationNames?.length === 0 ? (
                <p>No Items</p>
              ) : (
                uniqueClassificationNames?.map((item: any) => {
                  return (
                    <Tag color="blue" key={item}>
                      <p>{item}</p>
                    </Tag>
                  );
                })
              )}
            </div>
            <p className="font-bold text-xl ml-5 mt-2">Sub Classifications</p>
            <div className="flex flex-row items-center gap-x-2 ml-5 flex-wrap">
              {channel?.subClassifications?.length === 0 ? (
                <p>No Items</p>
              ) : (
                channel?.subClassifications?.map((item: any) => {
                  return (
                    <Tag color="blue" key={item}>
                      <p>{item?.name}</p>
                    </Tag>
                  );
                })
              )}
            </div>
          </>
        )}
      </div>

      <div
        className={`w-[90%] ${
          !isMax1110 ? "h-[30rem]" : "h-[35rem]"
        } bg-white flex flex-col justify-start items-between shadow-md rounded-lg`}
      >
        <div className="w-full h-[4rem] px-4 bg-[#F9FAFB] border border-[#E6E8EC] flex flex-row items-end justify-between rounded-tr-lg rounded-tl-lg">
          <Tabs
            defaultActiveKey="1"
            items={user?.role === "ADMIN" ? adminItems : items}
            onChange={(value) => {
              if (value === "1") {
                setSelectedCategory("merchants");
                dispatch(listMerchantsAction());
              } else if (value === "2") {
                setSelectedCategory("categories");
                dispatch(listCategoriesAction());
              } else if (value === "3") {
                setSelectedCategory("sub_categories");
                dispatch(listSubCategoriesAction());
              }
            }}
          />
          {!isMax1110 && user?.role === "ADMIN" && (
            <div className="h-[4rem] flex items-center justify-center gap-x-3">
              <Input
                prefix={<SearchOutlined />}
                onChange={(e: any) => setSearch(e.target.value)}
                className="!h-[2.5rem] !w-[18rem]"
                placeholder="Search by id, name, or email"
              />
              <button
                onClick={() => {
                  if (selectedCategory === "merchants") {
                    navigate(CREATE_MERCHANT_PATH);
                  } else if (selectedCategory === "categories") {
                    navigate(CREATE_CATEGORY_PATH);
                  } else if (selectedCategory === "sub_categories") {
                    navigate(CREATE_SUB_CATEGORY_PATH);
                  }
                }}
                className="px-6 py-4 w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-[--primary]"
              >
                <PlusOutlined className="font-bold text-white" />
              </button>
            </div>
          )}
        </div>
        {selectedCategory === "merchants" && (
          <Table
            className="m-4 shadow-md overflow-x-auto"
            dataSource={merchants?.filter((item: any) =>
              item.name.toLowerCase().includes(search.toLowerCase())
            )}
            loading={merchantsLoading || deleteLoading || merchantLoading}
            columns={MerchantTableCols(
              imageModalOpen,
              setImageModalOpen,
              image,
              setImage,
              (value: any) => {
                setModalOpen(true);
                setDeletingId(value);
              },
              async (value: any) => {
                await dispatch(listSingleMerchantAction(value ?? ""));
                navigate(`/dashboard/merchants/update/${value}`);
              },
              true,
              async (value: any) => {
                await dispatch(listSingleMerchantAction(value));
                navigate(`/dashboard/merchant-channel/${value}`);
              },
              user?.role === "ADMIN",
              user?.role === "CHANNEL_ADMIN"
            )}
          />
        )}
        {selectedCategory === "categories" && (
          <Table
            className="m-4 shadow-md overflow-x-auto"
            dataSource={categories}
            loading={
              categoriesLoading || deleteCategoryLoading || categoryLoading
            }
            columns={CategoryTableCols(
              (value: any) => {
                setModalOpen(true);
                setDeletingId(value);
              },
              async (value: any) => {
                await dispatch(listSingleCategoryAction(value ?? ""));
                navigate(`/dashboard/categories/update/${value}`);
              }
            )}
          />
        )}
        {selectedCategory === "sub_categories" && (
          <Table
            className="m-4 shadow-md overflow-x-auto"
            dataSource={subCategories}
            loading={
              subCategoriesLoading ||
              deleteCategoryLoading ||
              subCategoryLoading
            }
            columns={SubCategoryTableCols(
              (value: any) => {
                setModalOpen(true);
                setDeletingId(value);
              },
              async (value: any) => {
                await dispatch(listSingleSubCategoryAction(value ?? ""));
                navigate(`/dashboard/sub-categories/update/${value}`);
              }
            )}
          />
        )}
        <Modal
          title="Delete Confirmation"
          open={modalOpen}
          onOk={async () => {
            if (selectedCategory === "merchants") {
              await dispatch(deleteMerchantAction(deletingId));
              setModalOpen(false);
              dispatch(dispatch(listMerchantsAction()));
            } else if (selectedCategory === "categories") {
              await dispatch(deleteCategoryAction(deletingId));
              setModalOpen(false);
              dispatch(listCategoriesAction());
            } else if (selectedCategory === "sub_categories") {
              await dispatch(deleteSubCategoryAction(deletingId));
              setModalOpen(false);
              dispatch(listSubCategoriesAction());
            }
          }}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{
            className: "bg-[--primary]",
          }}
          confirmLoading={
            deleteLoading || deleteCategoryLoading || deleteSubCategoryLoading
          }
        >
          <p>Are you sure you want to delete: {deletingId}</p>
        </Modal>
      </div>
    </div>
  );
};

export default Merchants;
