import
{
    UPDATE_CHANNEL_LOADING,
    UPDATE_CHANNEL_SUCCESS,
    UPDATE_CHANNEL_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    updatedChannel: null,
    errors: null
}

export const updateChannelReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_CHANNEL_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_CHANNEL_SUCCESS :
            return {...state, updatedChannel: action.payload.data};
        case UPDATE_CHANNEL_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}