import
{
    GET_SUBCATEGORY_LOADING,
    GET_SUBCATEGORY_SUCCESS,
    GET_SUBCATEGORY_FAILURE
}
from "redux/types";

const initialState = {
    subCategoryLoading: false,
    subCategory: null,
    errors: null
}

export const listSingleSubCategoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_SUBCATEGORY_LOADING :
            return {...state, subCategoryLoading: action.payload};
        case GET_SUBCATEGORY_SUCCESS :
            return {...state, subCategory: action.payload.data};
        case GET_SUBCATEGORY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}