import { Select } from "antd";
import Loader from "components/Loader/Loader";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import * as yup from "yup";
import CreateChannelMerchantModel from "models/CreateChannelMerchantModel";
import { useDispatch } from "react-redux";
import { createChannelMerchantAction } from "redux/actions/channelMerchantsActions/createChannelMerchantAction";
import { useNavigate } from "react-router";
import { useDropdown } from "hooks/useDropdown";

const CreateChannelMerchant = () => {
  const { createLoading, channelId } = useSelector(
    (state: RootState) => state.createChannelMerchantReducer
  );
  const { channel } = useSelector(
    (state: RootState) => state.listSingleChannelReducer
  );

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { merchantsDropdown } = useDropdown();

  const formik = useFormik({
    initialValues: {
      channelId: channel?.id,
      merchantId: "",
    },
    validationSchema: yup.object({
      channelId: yup.string().required("please enter this field"),
      merchantId: yup.string().required("please enter this field"),
    }),
    onSubmit: async (values: CreateChannelMerchantModel) => {
      await dispatch(
        createChannelMerchantAction(
          {
            channel_id: values.channelId,
            merchant_id: values.merchantId,
          },
          () => navigate(-1)
        )
      );
    },
  });

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Link Merchant</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Channel</label>
              <Select
                placeholder="Channel"
                className="!h-[2.5rem] w-full"
                value={formik.values.channelId}
                disabled
                onChange={formik.handleChange}
              />
              {formik.errors.channelId && (
                <p className="text-[red]">{formik.errors.channelId}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Merchant</label>
              <Select
                placeholder="Merchant"
                className="!h-[2.5rem] w-full"
                options={merchantsDropdown}
                onSelect={(value: any) => {
                  formik.setFieldValue("merchantId", value);
                }}
              />
              {formik.errors.merchantId && (
                <p className="text-[red]">please provide this field</p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {createLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Link</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateChannelMerchant;
