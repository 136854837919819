import { Input, Select, Spin } from "antd";
import Loader from "components/Loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import CreateMerchantModel from "models/CreateMerchantModel";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDispatch } from "react-redux";
import { createMerchantAction } from "redux/actions/merchantsActions/createMerchantAction";
import { useNavigate } from "react-router";
import { useDropdown } from "hooks/useDropdown";
import { useEffect, useState } from "react";
import { listSubCategoriesAction } from "redux/actions/subCategoriesActions/listSubCategoriesAction";

const CreateMerchant = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      logo: null,
      subCategories: null
    },
    validationSchema: yup.object({
      name: yup.string().required("please enter this field"),
    }),
    onSubmit: async (values: CreateMerchantModel) => {
      if (!values.logo) {
        formik.setFieldError("logo", "err");
        return;
      }
      
      await dispatch(
        createMerchantAction(
          {
            name: values.name,
            logo: values.logo,
            subCategories: values.subCategories
          },
          () => navigate(-1)
        )
      );
      
    },
  });

  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const { createLoading } = useSelector(
    (state: RootState) => state.createMerchantReducer
  );

  const { subCategoriesLoading, subCategories } = useSelector(
    (state: RootState) => state.listSubCategoriesReducer
  );

  const { categoriesDropdown } = useDropdown();

  const [categoryId, setCategoryId] = useState("");

  const subCategoriesDropdown = subCategories?.map((item: any) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  useEffect(() => {
    dispatch(listSubCategoriesAction(categoryId));
  }, [categoryId]);

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Create Merchant</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Name</label>
              <Input
                placeholder="Name"
                name="name"
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <p className="text-[red]">{formik.errors.name}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Logo</label>
              <Input
                placeholder="Email"
                type="file"
                name="email"
                className="!h-[2.5rem] w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files !== null) {
                    formik.setFieldValue("logo", e.target.files[0]);
                  }
                }}
              />
              {formik.errors.logo && (
                <p className="text-[red]">please provide this field</p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Category</label>
              <Select
                options={categoriesDropdown}
                onSelect={(value) => {
                  setCategoryId(value);
                }}
                className="w-full"
                placeholder="Category"
              />
              {formik.errors.name && (
                <p className="text-[red]">{formik.errors.name}</p>
              )}
            </div>
            <div className="select-spin-container w-[50%] flex flex-col items-start">
              <label>Sub Categories</label>
              <Spin spinning={subCategoriesLoading}>
                <Select
                  options={subCategoriesDropdown}
                  onChange={(value: any) => {
                    formik.setFieldValue('subCategories', value);
                  }}
                  mode="multiple"
                  allowClear
                  className="w-full"
                  placeholder="Category"
                />
                {formik.errors.subCategories && (
                  <p className="text-[red]">please provide this field</p>
                )}
              </Spin>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {createLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Create</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateMerchant;
