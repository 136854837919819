import
{
    LIST_SUBCLASSIFICATIONS_LOADING,
    LIST_SUBCLASSIFICATIONS_SUCCESS,
    LIST_SUBCLASSIFICATIONS_FAILURE
}
from "redux/types";

const initialState = {
    subClassificationsLoading: false,
    subClassifications: null,
    errors: null
}

export const listSubClassificationsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_SUBCLASSIFICATIONS_LOADING :
            return {...state, subClassificationsLoading: action.payload};
        case LIST_SUBCLASSIFICATIONS_SUCCESS :
            return {...state, subClassifications: action.payload.data};
        case LIST_SUBCLASSIFICATIONS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}