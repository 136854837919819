import
{
    GET_OFFER_CODE_LOADING,
    GET_OFFER_CODE_SUCCESS,
    GET_OFFER_CODE_FAILURE
}
from "redux/types";

const initialState = {
    codeLoading: false,
    code: null,
    errors: null
}

export const getOfferCodeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_OFFER_CODE_LOADING :
            return {...state, codeLoading: action.payload};
        case GET_OFFER_CODE_SUCCESS :
            return {...state, code: action.payload.data};
        case GET_OFFER_CODE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}