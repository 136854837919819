import
{
    UPDATE_CATEGORY_LOADING,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateItemService } from "services/updateItemService";

export const updateCategoryAction = (id: string, data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_CATEGORY_LOADING, payload: true});
        const response = await updateItemService('/api/stlr/category/', id, data);
        dispatch({type: UPDATE_CATEGORY_SUCCESS, payload: response.data});
        callback && callback();
    } catch (err) {
        dispatch({type: UPDATE_CATEGORY_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_CATEGORY_LOADING, payload: false});
    }
}