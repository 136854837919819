import
{
    CREATE_OFFER_LOADING,
    CREATE_OFFER_SUCCESS,
    CREATE_OFFER_FAILURE,
    SET_MERCHANT_ID,
}
from "redux/types";

const initialState = {
    createLoading: false,
    newOffer: null,
    merchantId: null,
    errors: null
}

export const createOfferReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_OFFER_LOADING :
            return {...state, createLoading: action.payload};
        case CREATE_OFFER_SUCCESS :
            return {...state, newOffer: action.payload.data};
        case SET_MERCHANT_ID :
            return {...state, merchantId: action.payload};
        case CREATE_OFFER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}