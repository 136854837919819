import
{
    DELETE_CHANNEL_MERCHANT_LOADING,
    DELETE_CHANNEL_MERCHANT_SUCCESS,
    DELETE_CHANNEL_MERCHANT_FAILURE
}
from "redux/types";

const initialState = {
    deleteLoading: false,
    success: null,
    errors: null
}

export const deleteChannelMerchantReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_CHANNEL_MERCHANT_LOADING :
            return {...state, deleteLoading: action.payload};
        case DELETE_CHANNEL_MERCHANT_SUCCESS :
            return {...state, success: action.payload.sucess};
        case DELETE_CHANNEL_MERCHANT_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}