import
{
    UPDATE_SUBCLASSIFICATION_LOADING,
    UPDATE_SUBCLASSIFICATION_SUCCESS,
    UPDATE_SUBCLASSIFICATION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateItemService } from "services/updateItemService";

export const updateSubClassificationAction = (id: string, data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_SUBCLASSIFICATION_LOADING, payload: true});
        const response = await updateItemService('/api/stlr/subClassification/', id, data);
        dispatch({type: UPDATE_SUBCLASSIFICATION_SUCCESS, payload: response.data});
        callback && callback();
    } catch (err) {
        dispatch({type: UPDATE_SUBCLASSIFICATION_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_SUBCLASSIFICATION_LOADING, payload: false});
    }
}