import
{
    DELETE_CATEGORY_LOADING,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE
}
from "redux/types";

const initialState = {
    deleteCategoryLoading: false,
    success: null,
    errors: null
}

export const deleteCategoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_CATEGORY_LOADING :
            return {...state, deleteCategoryLoading: action.payload};
        case DELETE_CATEGORY_SUCCESS :
            return {...state, success: action.payload.success};
        case DELETE_CATEGORY_FAILURE :
            return {...state, errors: action.paylad};
        default:
            return state;
    }
}