import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Modal, Table } from "antd";
import { useResponsive } from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { listOffersAction } from "redux/actions/offersActions/listOffersAction";
import { RootState } from "redux/rootReducer";
import OfferTableCols from "models/tableColumns/OfferTableCols";
import { CREATE_CHANNEL_OFFER_PATH, CREATE_OFFER_PATH } from "Routes/paths";
import { listSingleOfferAction } from "redux/actions/offersActions/listSingleOfferAction";
import { deleteOfferAction } from "redux/actions/offersActions/deleteOfferAction";
import { SET_MERCHANT_ID } from "redux/types";
import { listChannelOffersAction } from "redux/actions/channelOffersActions/listChannelOffersAction";

const Offers = () => {
  const dispatch: any = useDispatch();

  const { isMax1110 } = useResponsive();

  const [modalOpen, setModalOpen] = useState(false);

  const [deletingId, setDeletingId] = useState("");

  const [search, setSearch] = useState("");

  const [admin, setAdmin] = useState(true);

  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.loginReducer);

  const { offersLoading, offers } = useSelector(
    (state: RootState) => state.listOffersReducer
  );
  const { offerLoading } = useSelector(
    (state: RootState) => state.listSingleOfferReducer
  );
  const { deleteLoading } = useSelector(
    (state: RootState) => state.deleteOfferReducer
  );
  const { channelOffersLoading, channelOffers } = useSelector(
    (state: RootState) => state.listChannelOffersReducer
  );

  useEffect(() => {
    console.log(user);
    if (user?.role !== "ADMIN") {
      setAdmin(false);
      if (user?.role === "CHANNEL_ADMIN") {
        dispatch(listChannelOffersAction(user?.channel?.id));
      } else if (user?.role === "MERCHANT_ADMIN") {
        dispatch(listOffersAction(user?.merchant?.id));
      }
    } else {
      setAdmin(true);
      dispatch(listOffersAction(""));
    }
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-3">
      {isMax1110 && admin && (
        <div
          onClick={() => {
            dispatch({ type: SET_MERCHANT_ID, payload: null });
            navigate(CREATE_OFFER_PATH);
          }}
          className="w-[3rem] h-[3rem] absolute bottom-10 right-10 bg-[--primary] rounded-full flex items-center justify-center"
        >
          <PlusOutlined className="text-white text-2xl" />
        </div>
      )}

      {isMax1110 && (
        <Input
          prefix={<SearchOutlined />}
          onChange={(e: any) => setSearch(e.target.value)}
          className="!h-[2.5rem] !w-[90%]"
          placeholder="Search by id, name, or email"
        />
      )}

      <div
        className={`w-[90%] ${
          !isMax1110 ? "h-[30rem]" : "h-[35rem]"
        } bg-white flex flex-col justify-start items-between shadow-md rounded-lg`}
      >
        <div className="w-full h-[4rem] px-4 bg-[#F9FAFB] border border-[#E6E8EC] flex flex-row items-end justify-between rounded-tr-lg rounded-tl-lg">
          {!isMax1110 && (
            <div className="h-[4rem] flex items-center justify-center gap-x-3">
              <Input
                prefix={<SearchOutlined />}
                onChange={(e: any) => setSearch(e.target.value)}
                className="!h-[2.5rem] !w-[18rem]"
                placeholder="Search by id, name, or email"
              />
              {admin && (
                <button
                  onClick={() => {
                    dispatch({ type: SET_MERCHANT_ID, payload: null });
                    navigate(CREATE_OFFER_PATH);
                  }}
                  className="px-6 py-4 w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-[--primary]"
                >
                  <PlusOutlined className="font-bold text-white" />
                </button>
              )}
            </div>
          )}
        </div>
        <Table
          className="m-4 shadow-md overflow-x-auto"
          dataSource={
            offers?.filter((item: any) =>
              item.merchant.name.toLowerCase().includes(search.toLowerCase())
            ) ||
            channelOffers?.filter((item: any) =>
              item.merchant.name
                .toLowerCase()
                .includes(search.toLocaleLowerCase())
            )
          }
          loading={offersLoading || offerLoading || channelOffersLoading}
          columns={OfferTableCols(
            (value: any) => {
              setModalOpen(true);
              setDeletingId(value);
            },
            async (value: any) => {
              await dispatch(listSingleOfferAction(value ?? ""));
              navigate(`/dashboard/offers/update/${value}`);
            },
            async (value: any) => {
              await dispatch(listSingleOfferAction(value ?? ""));
              navigate(CREATE_CHANNEL_OFFER_PATH);
            },
            true,
            (value: any) => {
              navigate(`/dashboard/offers/view/${value}`);
            },
            admin
          )}
        />
        <Modal
          title="Delete Confirmation"
          open={modalOpen}
          onOk={async () => {
            await dispatch(deleteOfferAction(deletingId));
            setModalOpen(false);
            dispatch(listOffersAction(""));
          }}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{
            className: "bg-[--primary]",
          }}
          confirmLoading={deleteLoading}
        >
          <p>Are you sure you want to delete: {deletingId}</p>
        </Modal>
      </div>
    </div>
  );
};

export default Offers;
