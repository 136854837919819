import
{
    DELETE_CLASSIFICATION_LOADING,
    DELETE_CLASSIFICATION_SUCCESS,
    DELETE_CLASSIFICATION_FAILURE
}
from "redux/types";

const initialState = {
    deleteClassificationLoading: false,
    success: null,
    errors: null
}

export const deleteClassificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_CLASSIFICATION_LOADING :
            return {...state, deleteClassificationLoading: action.payload};
        case DELETE_CLASSIFICATION_SUCCESS :
            return {...state, success: action.payload.success};
        case DELETE_CLASSIFICATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}