import
{
    LIST_MERCHANT_CHANNELS_LOADING,
    LIST_MERCHANT_CHANNELS_SUCCESS,
    LIST_MERCHANT_CHANNELS_FAILURE
}
from "redux/types";

const initialState = {
    merchantChannelsLoading: false,
    merchantChannels: null,
    errors: null
}

export const listMerchantChannelsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_MERCHANT_CHANNELS_LOADING :
            return {...state, merchantChannelsLoading: action.payload};
        case LIST_MERCHANT_CHANNELS_SUCCESS :
            return {...state, merchantChannels: action.payload.data};
        case LIST_MERCHANT_CHANNELS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}