import
{
    CREATE_CLASSIFICATION_LOADING,
    CREATE_CLASSIFICATION_SUCCESS,
    CREATE_CLASSIFICATION_FAILURE
}
from "redux/types";

const initialState = {
    createLoading: false,
    newClassification: null,
    errors: null
}

export const createClassificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_CLASSIFICATION_LOADING :
            return {...state, createLoading: action.payload};
        case CREATE_CLASSIFICATION_SUCCESS :
            return {...state, newClassification: action.payload.data};
        case CREATE_CLASSIFICATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}