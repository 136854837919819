import
{
    GET_OFFER_LOADING,
    GET_OFFER_SUCCESS,
    GET_OFFER_FAILURE
}
from "redux/types";

const initialState = {
    offerLoading: false,
    offer: null,
    errors: null
}

export const listSingleOfferReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_OFFER_LOADING :
            return {...state, offerLoading: action.payload};
        case GET_OFFER_SUCCESS :
            return {...state, offer: action.payload.data};
        case GET_OFFER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}