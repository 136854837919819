import
{
    LIST_SUBCLASSIFICATIONS_LOADING,
    LIST_SUBCLASSIFICATIONS_SUCCESS,
    LIST_SUBCLASSIFICATIONS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listSubClassificationsAction = (classificationId?: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_SUBCLASSIFICATIONS_LOADING, payload: true});
        const response = await listItemsService(`/api/stlr/subClassification?classification_id=${classificationId}`);
        dispatch({type: LIST_SUBCLASSIFICATIONS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_SUBCLASSIFICATIONS_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_SUBCLASSIFICATIONS_LOADING, payload: false});
    }
}