import
{
    CREATE_MERCHANT_LOADING,
    CREATE_MERCHANT_SUCCESS,
    CREATE_MERCHANT_FAILURE
}
from "redux/types";

const initialState = {
    createLoading: false,
    merchant: null,
    errors: null
}

export const createMerchantReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_MERCHANT_LOADING :
            return {...state, createLoading: action.payload};
        case CREATE_MERCHANT_SUCCESS :
            return {...state, merchant: action.payload.data};
        case CREATE_MERCHANT_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}