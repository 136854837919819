import { combineReducers } from "redux";
import { loginReducer } from "redux/reducers/loginReducer";
import { createUserReducer } from "redux/reducers/userReducers/createUserReducer";
import { listUsersReducer } from "redux/reducers/userReducers/listUsersReducer";
import { deleteUserReducer } from "redux/reducers/userReducers/deleteUserReducer";
import { getSingleUserReducer } from "redux/reducers/userReducers/getSingleUserReducer";
import { updateUserReducer } from "redux/reducers/userReducers/updateUserReducer";
import { listMerchantsReducer } from "redux/reducers/merchantsReducers/listMerchantsReducer";
import { deleteMerchantReducer } from "redux/reducers/merchantsReducers/deleteMerchantReducer";
import { createMerchantReducer } from "redux/reducers/merchantsReducers/createMerchantReducer";
import { listSingleMerchantReducer } from "redux/reducers/merchantsReducers/listSingleMerchantReducer";
import { updateMerchantReducer } from "redux/reducers/merchantsReducers/updateMerchantReducer";
import { listChannelsReducer } from "redux/reducers/channelsReducers/listChannelsReducer";
import { createChannelReducer } from "redux/reducers/channelsReducers/createChannelReducer";
import { deleteChannelReducer } from "redux/reducers/channelsReducers/deleteChannelReducer";
import { listSingleChannelReducer } from "redux/reducers/channelsReducers/listSingleChannelReducer";
import { updateChannelReducer } from "redux/reducers/channelsReducers/updateChannelReducer";
import { listCategoriesReducer } from "redux/reducers/categoriesReducers/listCategoriesReducer";
import { createCatgoryReducer } from "redux/reducers/categoriesReducers/createCategoryReducer";
import { listSingleCategoryReducer } from "redux/reducers/categoriesReducers/listSingleCategoryReducer";
import { updateCategoryReducer } from "redux/reducers/categoriesReducers/updateCategoryReducer";
import { deleteCategoryReducer } from "redux/reducers/categoriesReducers/deleteCategoryReducer";
import { listSubCategoriesReducer } from "redux/reducers/subCategoriesReducers/listSubCategoriesReducer";
import { createSubCategoryReducer } from "redux/reducers/subCategoriesReducers/createSubCategoryReducer";
import { listSingleSubCategoryReducer } from "redux/reducers/subCategoriesReducers/listSingleSubCategoryReducer";
import { updateSubCategoryReducer } from "redux/reducers/subCategoriesReducers/updateSubCategoryReducer";
import { deleteSubCategoryReducer } from "redux/reducers/subCategoriesReducers/deleteSubCategoryReducer";
import { listClassificationsReducer } from "redux/reducers/classificationsReducers/listClassificationsReducer";
import { createClassificationReducer } from "redux/reducers/classificationsReducers/createClassificationReducer";
import { listSingleClassificationReducer } from "redux/reducers/classificationsReducers/listSingleClassificationReducer";
import { updateClassificationReducer } from "redux/reducers/classificationsReducers/updateClassificationReducer";
import { deleteClassificationReducer } from "redux/reducers/classificationsReducers/deleteClassificationReducer";
import { listSubClassificationsReducer } from "redux/reducers/subClassificationsRedcuers/listSubClassificationsReducer";
import { createSubClassificationReducer } from "redux/reducers/subClassificationsRedcuers/createSubClassificationReducer";
import { listSingleSubClassificationReducer } from "redux/reducers/subClassificationsRedcuers/listSingleSubClassificationReducer";
import { updateSubClassificationReducer } from "redux/reducers/subClassificationsRedcuers/updateSubClassificationReducer";
import { deleteSubClassificationReducer } from "redux/reducers/subClassificationsRedcuers/deleteSubClassificationReducer";
import { createChannelMerchantReducer } from "redux/reducers/channelMerchantsReducers/createChannelMerchantReducer";
import { listChannelMerchantReducer } from "redux/reducers/channelMerchantsReducers/listChannelMerchantsReducer";
import { deleteChannelMerchantReducer } from "redux/reducers/channelMerchantsReducers/deleteChannelMerchantReducer";
import { listMerchantChannelsReducer } from "redux/reducers/merchantChannelsReducers/listMerchantChannelsReducer";
import { listOffersReducer } from "redux/reducers/offersReducers/listOffersReducers";
import { createOfferReducer } from "redux/reducers/offersReducers/createOfferReducer";
import { listSingleOfferReducer } from "redux/reducers/offersReducers/listSingleOfferReducer";
import { updateOfferReducer } from "redux/reducers/offersReducers/updateOfferReducer";
import { deleteOfferReducer } from "redux/reducers/offersReducers/deleteOfferReducer";
import { createChannelOfferReducer } from "redux/reducers/channelOffersReducers/createChannelOfferReducer";
import { listChannelOffersReducer } from "redux/reducers/channelOffersReducers/listChannelOffersReducer";
import { listOfferCodesReducer } from "redux/reducers/offerCodesReducers/listOfferCodesReducer";
import { uploadOfferCodesReducer } from "redux/reducers/offerCodesReducers/uploadOfferCodesReducer";
import { generateOfferCodesReducer } from "redux/reducers/offerCodesReducers/generateOfferCodesReducer";
import { getOfferCodeReducer } from "redux/reducers/offerCodesReducers/getOfferCodeReducer";
import { updateOfferCodeReducer } from "redux/reducers/offerCodesReducers/updateOfferCodeReducer";
import { listVouchersReducer } from "redux/reducers/vouchersReducers/listVouchersReducer";
import { createVoucherReducer } from "redux/reducers/vouchersReducers/createVoucherReducer";

export const appReducer = combineReducers({
  loginReducer,
  createUserReducer,
  listUsersReducer,
  deleteUserReducer,
  getSingleUserReducer,
  updateUserReducer,
  listMerchantsReducer,
  deleteMerchantReducer,
  createMerchantReducer,
  listSingleMerchantReducer,
  updateMerchantReducer,
  listChannelsReducer,
  createChannelReducer,
  deleteChannelReducer,
  listSingleChannelReducer,
  updateChannelReducer,
  listCategoriesReducer,
  createCatgoryReducer,
  listSingleCategoryReducer,
  updateCategoryReducer,
  deleteCategoryReducer,
  listSubCategoriesReducer,
  createSubCategoryReducer,
  listSingleSubCategoryReducer,
  updateSubCategoryReducer,
  deleteSubCategoryReducer,
  listClassificationsReducer,
  createClassificationReducer,
  listSingleClassificationReducer,
  updateClassificationReducer,
  deleteClassificationReducer,
  listSubClassificationsReducer,
  createSubClassificationReducer,
  listSingleSubClassificationReducer,
  updateSubClassificationReducer,
  deleteSubClassificationReducer,
  createChannelMerchantReducer,
  listChannelMerchantReducer,
  deleteChannelMerchantReducer,
  listMerchantChannelsReducer,
  listOffersReducer,
  createOfferReducer,
  listSingleOfferReducer,
  updateOfferReducer,
  deleteOfferReducer,
  createChannelOfferReducer,
  listChannelOffersReducer,
  listOfferCodesReducer,
  uploadOfferCodesReducer,
  generateOfferCodesReducer,
  getOfferCodeReducer,
  updateOfferCodeReducer,
  listVouchersReducer,
  createVoucherReducer
});

export const rootReducers = (state: any, action: any) => {
  if (action.type === "LOG_OUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
