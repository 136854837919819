import styles from "screens/Login/Login.module.css";
import { Input } from "antd";
import { useResponsive } from "hooks/useResponsive";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PrimaryStlrLogo, WhiteStlrLogo } from "assets/icons";
import { useFormik } from "formik";
import UserLoginModel from "models/UserLoginModel";
import * as Yup from "yup";
import Loader from "components/Loader/Loader";
import { loginAction } from "redux/actions/loginAction";

const Login = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("please enter a valid email")
        .required("please provide this field"),
      password: Yup.string().required().min(8),
    }),
    onSubmit: async (values: UserLoginModel) => {
      await dispatch(loginAction(values.email, values.password));
    },
  });

  const { Password } = Input;

  const navigate = useNavigate();

  const { loading, user } = useSelector((state: RootState) => state.loginReducer);
  const dispatch: any = useDispatch();

  const { isMobile } = useResponsive();

  useEffect(() => {
    if(user) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className={`w-full h-full flex ${isMobile ? "flex-col" : "flex-row"}`}>
      <img
        src={PrimaryStlrLogo}
        alt="stlr-logo"
        className="h-[2.5rem] absolute top-6 right-6"
      />

      {!isMobile && (
        <div className="w-[50%] flex items-center justify-center h-full bg-[--primary]">
          <img
            src={WhiteStlrLogo}
            alt="stlr-logo"
            className="w-[12rem] h-[12rem]"
          />
        </div>
      )}

      <div
        className={`${
          isMobile ? "w-full" : "w-[50%]"
        } h-full flex flex-col items-center justify-center`}
      >
        <div
          className={`${isMobile ? "w-full px-3" : ""} flex flex-col items-start`}
        >
          <h1 className="text-[--primary] text-4xl font-bold">Welcome !</h1>
          <p className="text-gray-400">please enter your details.</p>
          <form
            className={`mt-4 ${
              isMobile ? "w-[95%]" : "w-[414px]"
            } flex flex-col gap-y-2`}
            onSubmit={formik.handleSubmit}
          >
            <label className="">Email/Username</label>
            <Input
              placeholder="Email/Username"
              name="email"
              className="w-full !h-[2.5rem]"
              onChange={formik.handleChange}
            />
            {formik.errors.email && (
              <p className="text-[red]">{formik.errors.email}</p>
            )}
            <label className="">Password</label>
            <Password
              placeholder="Password"
              name="password"
              className="w-full !h-[2.5rem]"
              onChange={formik.handleChange}
            />
            {formik.errors.password && (
              <p className="text-[red]">Please Enter This Field</p>
            )}
            <div className="mt-4 w-full flex items-center justify-center">
              <button
                type="submit"
                disabled={loading}
                className="w-[80%] h-[3rem] rounded-md bg-[--primary] flex items-center justify-center"
              >
                {loading ? <Loader /> : <p className="text-white font-bold">Login</p>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
