import
{
    LIST_USERS_LOADING,
    LIST_USERS_SUCCESS,
    LIST_USERS_FAILURE
}
from "redux/types";

const initialState = {
    loading: false, 
    users: null,
    errors: null
};

export const listUsersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_USERS_LOADING :
            return {...state, loading: action.payload};
        case LIST_USERS_SUCCESS :
            return {...state, users: action.payload.data};
        case LIST_USERS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}