import
{
    LIST_OFFER_CODES_LOADING,
    LIST_OFFER_CODES_SUCCESS,
    LIST_OFFER_CODES_FAILURE
}
from "redux/types";

const initialState = {
    codesLoading: false,
    codes: null,
    errors: null
}

export const listOfferCodesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_OFFER_CODES_LOADING :
            return {...state, codesLoading: action.payload};
        case LIST_OFFER_CODES_SUCCESS :
            return {...state, codes: action.payload.data};
        case LIST_OFFER_CODES_FAILURE :
            return {...state, errors: action.payloda};
        default:
            return state;
    }
}