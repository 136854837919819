import AppRoutes from "./Routes/AppRoutes";

import "./App.css";

function App() {
  return (
    <div className="App w-full h-[100vh]">
      <AppRoutes />
    </div>
  );
}

export default App;
