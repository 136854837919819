import
{
    DELETE_OFFER_LOADING,
    DELETE_OFFER_SUCCESS,
    DELETE_OFFER_FAILURE
}
from "redux/types";

const initialState = {
    deleteLoading: false,
    success: null,
    errors: null
}

export const deleteOfferReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_OFFER_LOADING :
            return {...state, deleteLoading: action.payload};
        case DELETE_OFFER_SUCCESS :
            return {...state, success: action.payload.success};
        case DELETE_OFFER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}