import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { DASHBOARD_PATH, LOGIN_PATH } from "Routes/paths";

const Home = () => {
  const { user } = useSelector((state: RootState) => state.loginReducer);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(DASHBOARD_PATH);
    } else {
      navigate(LOGIN_PATH);
    }
  }, []);

  return <></>;
};

export default Home;
