import { Input, Modal, Select, Spin } from "antd";
import Loader from "components/Loader/Loader";
import { useFormik } from "formik";
import CreateChannelModel from "models/CreateChannelModel";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/rootReducer";
import * as yup from "yup";
import { updateChannelAction } from "redux/actions/channelsActions/updateChannelAction";
import { useEffect, useState } from "react";
import { listSubClassificationsAction } from "redux/actions/subClassificationsActions/listSubClassificationsAction";
import { useDropdown } from "hooks/useDropdown";

const UpdateChannel = () => {
  const { channel } = useSelector(
    (state: RootState) => state.listSingleChannelReducer
  );
  const { updateLoading } = useSelector(
    (state: RootState) => state.updateChannelReducer
  );
  const { subClassificationsLoading, subClassifications } = useSelector(
    (state: RootState) => state.listSubClassificationsReducer
  );

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const [classificationId, setClassificationId] = useState("");

  const { classificationsDropdown } = useDropdown();
  const subClassificationsDropdown = subClassifications?.map((item: any) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const formik = useFormik({
    initialValues: {
      name: channel?.name,
      logo: channel?.logo,
      subClassifications: channel?.subClassifications?.map((item: any) => {
        return {
          label: item.name,
          value: item.id
        }
      })
    },
    validationSchema: yup.object({
      name: yup.string().required("please enter this field"),
    }),
    onSubmit: async (values: CreateChannelModel) => {
      const form: any = {
        name: values.name,
        subClassifications: values.subClassifications.length > 0 ? values.subClassifications : []
      };
      if (typeof values.logo !== "string") {
        form.logo = values.logo;
      }
      await dispatch(
        updateChannelAction(channel?.uuid, form, () => navigate(-1))
      );
    },
  });

  useEffect(() => {
    dispatch(listSubClassificationsAction(classificationId));
  }, [classificationId]);

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Update Channel</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Name</label>
              <Input
                placeholder="Name"
                name="name"
                value={formik.values.name}
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <p className="text-[red]">{formik.errors.name}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Logo</label>
              <Input
                placeholder="Email"
                type="file"
                name="email"
                className="!h-[2.5rem] w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files !== null) {
                    formik.setFieldValue("logo", e.target.files[0]);
                  }
                }}
              />
              <p
                onClick={() => setModalOpen(true)}
                className="ml-2 text-blue-600 hover:cursor-pointer hover:opacity-80"
              >
                preview
              </p>
              {formik.errors.logo && (
                <p className="text-[red]">please provide this field</p>
              )}
              <Modal
                title="Logo"
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                okButtonProps={{
                  style: {
                    display: "none",
                  },
                }}
                cancelButtonProps={{
                  style: {
                    display: "none",
                  },
                }}
              >
                <img
                  src={`https://apis.st-lr.com/public/uploads/channelLogos/${formik.values.logo}`}
                  alt="channel-logo"
                  className="w-full h-full object-cover"
                />
              </Modal>
            </div>
          </div>

          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Classification</label>
              <Select
                options={classificationsDropdown}
                onSelect={(value) => {
                  setClassificationId(value);
                }}
                className="w-full"
                placeholder="Category"
              />
            </div>
            <div className="select-spin-container w-[50%] flex flex-col items-start">
              <label>Sub Classifications</label>
              <Spin spinning={subClassificationsLoading}>
                <Select
                  options={subClassificationsDropdown}
                  onChange={(value: any) => {
                    console.log(value);
                    formik.setFieldValue("subClassifications", value);
                  }}
                  value={formik.values.subClassifications}
                  mode="multiple"
                  allowClear
                  className="w-full"
                  placeholder="Category"
                />
              </Spin>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {updateLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Update</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateChannel;
