import
{
    GET_MERCHANT_LOADING,
    GET_MERCHANT_SUCCESS,
    GET_MERCHANT_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const listSingleMerchantAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: GET_MERCHANT_LOADING, payload:true});
        const response = await listSingleItemService('/api/stlr/merchant/', id);
        dispatch({type: GET_MERCHANT_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: GET_MERCHANT_FAILURE, payload: err});
    } finally {
        dispatch({type: GET_MERCHANT_LOADING, payload: false});
    }
}