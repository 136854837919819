import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Modal, Table } from "antd";
import { useResponsive } from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { listVoucherAction } from "redux/actions/vouchersActions/listVouchersAction";
import { RootState } from "redux/rootReducer";
import VouchersTableCols from "models/tableColumns/VouchersTableCols";
import { useNavigate } from "react-router";
import { CREATE_VOUCHER_PATH } from "Routes/paths";

const Vouchers = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.loginReducer);
  const { vouchersLoading, vouchers } = useSelector((state: RootState) => state.listVouchersReducer);

  const { isMax1110 } = useResponsive();

  const [search, setSearch] = useState("");
  const [deletingId, setDeletingId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    dispatch(listVoucherAction());
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-3">
      {isMax1110 && (
        <div
          onClick={() => {}}
          className="w-[3rem] h-[3rem] absolute bottom-10 right-10 bg-[--primary] rounded-full flex items-center justify-center z-10"
        >
          <PlusOutlined className="text-white text-2xl" />
        </div>
      )}

      {isMax1110 && (
        <Input
          prefix={<SearchOutlined />}
          onChange={(e: any) => setSearch(e.target.value)}
          className="!h-[2.5rem] !w-[90%]"
          placeholder="Search by id, name, or email"
        />
      )}

      <div
        className={`w-[90%] ${
          !isMax1110 ? "h-[30rem]" : "h-[35rem]"
        } bg-white flex flex-col justify-start items-between shadow-md rounded-lg`}
      >
        <div className="w-full h-[4rem] px-4 bg-[#F9FAFB] border border-[#E6E8EC] flex flex-row items-end justify-between rounded-tr-lg rounded-tl-lg">
          {!isMax1110 && user?.role === "ADMIN" && (
            <div className="h-[4rem] flex items-center justify-center gap-x-3">
              <Input
                prefix={<SearchOutlined />}
                onChange={(e: any) => setSearch(e.target.value)}
                className="!h-[2.5rem] !w-[18rem]"
                placeholder="Search by id, name, or email"
              />
              <button
                onClick={() => {
                    navigate(CREATE_VOUCHER_PATH);
                }}
                className="px-6 py-4 w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-[--primary]"
              >
                <PlusOutlined className="font-bold text-white" />
              </button>
            </div>
          )}
        </div>
        <Table
          className="m-4 shadow-md overflow-x-auto"
          dataSource={vouchers}
          loading={
            vouchersLoading
          }
          columns={VouchersTableCols(
            (value: any) => {
              setModalOpen(true);
              setDeletingId(value);
            },
            async (value: any) => {
            //   await dispatch(listSingleSubCategoryAction(value ?? ""));
            //   navigate(`/dashboard/sub-categories/update/${value}`);
            }
          )}
        />
        <Modal
          title="Delete Confirmation"
          open={modalOpen}
          onOk={async () => {}}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{
            className: "bg-[--primary]",
          }}
        //   confirmLoading={}
        >
          <p>Are you sure you want to delete: {deletingId}</p>
        </Modal>
      </div>
    </div>
  );
};

export default Vouchers;
