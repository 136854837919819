import { useSelector } from "react-redux";
import MainViewContainer from "../components/MainViewContainer/MainViewContainer";
import { Outlet, useNavigate } from "react-router-dom";
import { RootState } from "redux/rootReducer";
import { useEffect } from "react";
import { DASHBOARD_PATH, LOGIN_PATH } from "./paths";

const ProtectedRoutes = () => {
  const { user } = useSelector((state: RootState) => state.loginReducer);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(LOGIN_PATH);
    } else {
      navigate(DASHBOARD_PATH);
    }
  }, [user]);

  return (
    <MainViewContainer>
      <Outlet />
    </MainViewContainer>
  );
};

export default ProtectedRoutes;
