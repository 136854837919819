import
{
    UPDATE_CLASSIFICATION_LOADING,
    UPDATE_CLASSIFICATION_SUCCESS,
    UPDATE_CLASSIFICATION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateItemService } from "services/updateItemService";

export const updateClassificationAction = (id: string, data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_CLASSIFICATION_LOADING, payload: true});
        const response = await updateItemService('/api/stlr/classification/', id, data);
        dispatch({type: UPDATE_CLASSIFICATION_SUCCESS, payload: response.data});
        callback && callback();
    } catch (err) { 
        dispatch({type: UPDATE_CLASSIFICATION_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_CLASSIFICATION_LOADING, payload: false});
    }
}