import
{
    LOGIN_LOADING,
    LOGIN_SUCCESS, 
    LOGIN_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { loginService } from "services/loginService";

export const loginAction = (email: string, password: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LOGIN_LOADING, payload: true});
        const response = await loginService({email: email, password: password});
        dispatch({type: LOGIN_SUCCESS, payload: response.data});
        if (response.status === 200) {
            window.location.pathname = "/dashboard";
        }
    } catch (err) {
        dispatch({type: LOGIN_FAILURE, payload: err});
    } finally {
        dispatch({type: LOGIN_LOADING, payload: false});
    }
}