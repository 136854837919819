import
{
    DELETE_CLASSIFICATION_LOADING,
    DELETE_CLASSIFICATION_SUCCESS,
    DELETE_CLASSIFICATION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { deleteItemService } from "services/deleteItemService";

export const deleteClassificationAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: DELETE_CLASSIFICATION_LOADING, payload: true});
        const response = await deleteItemService('/api/stlr/classification/', id);
        dispatch({type: DELETE_CLASSIFICATION_SUCCESS, payload: response.data});
    } catch (err) { 
        dispatch({type: DELETE_CLASSIFICATION_FAILURE, payload: err});
    } finally {
        dispatch({type: DELETE_CLASSIFICATION_LOADING, payload: false});
    }
}