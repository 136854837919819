import
{
    GET_CATEGORY_LOADING,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAILURE
}
from "redux/types";

const initialState = {
    categoryLoading: false,
    category: null,
    errors: null
}

export const listSingleCategoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_CATEGORY_LOADING :
            return {...state, categoryLoading: action.payload};
        case GET_CATEGORY_SUCCESS :
            return {...state, category: action.payload.data};
        case GET_CATEGORY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}