import
{
    LIST_VOUCHERS_LOADING,
    LIST_VOUCHERS_SUCCESS,
    LIST_VOUCHERS_FAILURE
}
from "redux/types";

const initialState = {
    vouchersLoading: false,
    vouchers: null,
    errors: null
}

export const listVouchersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_VOUCHERS_LOADING :
            return {...state, vouchersLoading: action.payload};
        case LIST_VOUCHERS_SUCCESS :
            return {...state, vouchers: action.payload.data};
        case LIST_VOUCHERS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}