import
{
    UPDATE_OFFER_LOADING,
    UPDATE_OFFER_SUCCESS,
    UPDATE_OFFER_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    updatedOffer: null,
    errors: null
}

export const updateOfferReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_OFFER_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_OFFER_SUCCESS :
            return {...state, updatedOffer: action.payload.data};
        case UPDATE_OFFER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}