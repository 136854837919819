import { Input } from "antd";
import Loader from "components/Loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import CreateClassificationModel from "models/CreateClassificationModel";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { createClassificationAction } from "redux/actions/classificationsActions/createClassificationAction";
import { useNavigate } from "react-router";

const CreateClassification = () => {
  const dispatch: any = useDispatch();

  const { createLoading } = useSelector(
    (state: RootState) => state.createClassificationReducer
  );

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("please enter this field"),
    }),
    onSubmit: async (values: CreateClassificationModel) => {
      await dispatch(
        createClassificationAction(
          {
            name: values.name,
          },
          () => navigate(-1)
        )
      );
    },
  });

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Create Classification</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Name</label>
              <Input
                placeholder="Name"
                name="name"
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <p className="text-[red]">{formik.errors.name}</p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {createLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Create</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateClassification;
