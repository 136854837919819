import
{
    UPDATE_SUBCLASSIFICATION_LOADING,
    UPDATE_SUBCLASSIFICATION_SUCCESS,
    UPDATE_SUBCLASSIFICATION_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    updatedSubClassification: null,
    errors: null
}

export const updateSubClassificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_SUBCLASSIFICATION_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_SUBCLASSIFICATION_SUCCESS :
            return {...state, updatedSubClassification: action.payload.data};
        case UPDATE_SUBCLASSIFICATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}