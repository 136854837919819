import
{
    CREATE_CHANNEL_LOADING,
    CREATE_CHANNEL_SUCCESS,
    CREATE_CHANNEL_FAILURE
}
from "redux/types";

const initialState = {
    createLoading: false,
    newChannel: null,
    errors: null
}

export const createChannelReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_CHANNEL_LOADING :
            return {...state, createLoading: action.payload};
        case CREATE_CHANNEL_SUCCESS :
            return {...state, newChannel: action.payload.data};
        case CREATE_CHANNEL_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}