import
{
    CREATE_USER_LOADING,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";
import { createUserUrl } from "api/apiUrls";

export const createUserAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: CREATE_USER_LOADING, payload: true});
        const response = await createNewItemService(createUserUrl(), data);
        dispatch({type: CREATE_USER_SUCCESS, payload: response.data});
        callback && callback();
    } catch (err) {
        dispatch({type: CREATE_USER_FAILURE, payload: err});
    } finally {
        dispatch({type: CREATE_USER_LOADING, payload: false});
    }
}