import styles from "./MainViewContainer.module.css";
import { FC, useEffect, useState } from "react";
import { Layout } from "antd";
import Sidebar from "components/Sidebar/Sidebar";
import Navbar from "components/Navbar/Navbar";
import { useSelector } from "react-redux";
import { RootState } from "~/redux/rootReducer";
import { useNavigate } from "react-router";
const { Content } = Layout;

interface props {
  children: React.ReactNode;
}

const MainViewContainer: FC<props> = ({ children }) => {
  const [showSidebar, setShowSideBar] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);

  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.loginReducer);

  useEffect(() => {
    if (window.location.pathname.includes("dashboard")) {
      setShowSideBar(true);
      setShowNavbar(true);
    } 
    else {
      setShowNavbar(false);
      setShowSideBar(false);
    }
  }, [window.location.pathname, user]);

  return (
    <div
      className={`w-full h-full flex flex-col
      `}
    >
      {showNavbar && <Navbar />}
      <div className="w-full h-full flex flex-row">
        {showSidebar && <Sidebar />}
        <Content
          style={{
            height: window.location.pathname.includes("dashboard")
              ? "calc(100% - 50px)"
              : "calc(100%)",
            overflowY: "auto",
            backgroundColor: "rgb(246, 246, 246)",
          }}
        >
          {children}
        </Content>
      </div>
    </div>
  );
};

export default MainViewContainer;
