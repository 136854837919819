import
{
    DELETE_SUBCATEGORY_LOADING,
    DELETE_SUBCATEGORY_SUCCESS,
    DELETE_SUBCATEGORY_FAILURE
}
from "redux/types";

const initialState = {
    deleteSubCategoryLoading: false,
    success: null,
    errors: null
}

export const deleteSubCategoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_SUBCATEGORY_LOADING :
            return {...state, deleteSubCategoryLoading: action.payload};
        case DELETE_SUBCATEGORY_SUCCESS :
            return {...state, success: action.payload.success};
        case DELETE_SUBCATEGORY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;

    }
}