import
{
    GET_USER_LOADING,
    GET_USER_SUCCESS, 
    GET_USER_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const getSingleUserAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: GET_USER_LOADING, payload: true});
        const response = await listSingleItemService("/api/stlr/user/", id);
        dispatch({type: GET_USER_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: GET_USER_FAILURE, payload: err});
    } finally {
        dispatch({type: GET_USER_LOADING, payload: false});
    }
}