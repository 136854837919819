import
{
    DELETE_CHANNEL_LOADING,
    DELETE_CHANNEL_SUCCESS,
    DELETE_CHANNEL_FAILURE
}
from "redux/types";

const initialState = {
    deleteLoading: false,
    success: null,
    errors: null
}

export const deleteChannelReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_CHANNEL_LOADING :
            return {...state, deleteLoading: action.payload};
        case DELETE_CHANNEL_SUCCESS :
            return {...state, success: action.payload.success};
        case DELETE_CHANNEL_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}