import {
  InboxOutlined,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Input, Modal, Select, Table, UploadProps, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "components/Loader/Loader";
import { useResponsive } from "hooks/useResponsive";
import { listOfferCodesAction } from "redux/actions/offerCodesActions/listOfferCodesAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import OfferCodeTableCols from "models/tableColumns/OfferCodeTableCols";
import GenerateOfferCodesModel from "models/GenerateOfferCodesModel";
import { generateOfferCodesAction } from "redux/actions/offerCodesActions/generateOfferCodesAction";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDropdown } from "hooks/useDropdown";
import UploadOfferCodesModel from "models/UploadOfferCodesModel";
import { uploadOfferCodesAction } from "redux/actions/offerCodesActions/uploadOfferCodesAction";
import * as XLSX from "xlsx";
import { getOfferCodeAction } from "redux/actions/offerCodesActions/getOfferCodeAction";

const ViewOffer = () => {
  const { id } = useParams();

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { codesLoading, codes } = useSelector(
    (state: RootState) => state.listOfferCodesReducer
  );
  const { generateLoading } = useSelector(
    (state: RootState) => state.generateOfferCodesReducer
  );
  const { uploadLoading } = useSelector(
    (state: RootState) => state.uploadOfferCodesReducer
  );
  const { codeLoading } = useSelector(
    (state: RootState) => state.getOfferCodeReducer
  );

  const { offersDropdown } = useDropdown();

  const [modalOpen, setModalOpen] = useState(false);
  const [generateModalOpen, setGenerateModalOpen] = useState(false);

  const [deletingId, setDeletingId] = useState("");

  const { isMax1110 } = useResponsive();

  const generateCodesFormik = useFormik({
    initialValues: {
      offer_id: id + "",
      count: 0,
    },
    validationSchema: yup.object({
      offer_id: yup.string().required("please enter this field"),
      count: yup.number().required("please enter this field"),
    }),
    onSubmit: (values: GenerateOfferCodesModel) => {
      dispatch(
        generateOfferCodesAction(
          values.count,
          {
            offer_id: values.offer_id,
          },
          () => {
            setGenerateModalOpen(false);
            dispatch(listOfferCodesAction(id + ""));
          }
        )
      );
    },
  });

  const uploadCodesFormik = useFormik({
    initialValues: {
      offer_id: id + "",
      codes: "",
    },
    validationSchema: yup.object({
      offer_id: yup.string().required("please enter this field"),
      codes: yup.mixed().required("please enter this field"),
    }),
    onSubmit: (values: UploadOfferCodesModel) => {
      dispatch(
        uploadOfferCodesAction(
          {
            offer_id: values.offer_id,
            codes: values.codes,
          },
          () => {
            setModalOpen(false);
            dispatch(listOfferCodesAction(id + ""));
          }
        )
      );
    },
  });

  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".xlsx,.xls",
    beforeUpload: () => {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      const file = info.file;

      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = e.target?.result as ArrayBuffer;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const columnData = XLSX.utils
            .sheet_to_json(worksheet, { header: 1 })
            .slice(1);
          uploadCodesFormik.setFieldValue("codes", columnData.flat());
        };
        reader.readAsArrayBuffer(file);
      }
    },
    onDrop(e: any) {
      const file = e.dataTransfer.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = e.target?.result as ArrayBuffer;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const columnData = XLSX.utils
            .sheet_to_json(worksheet, { header: 1 })
            .slice(1);
          uploadCodesFormik.setFieldValue("codes", columnData.flat());
        };
        reader.readAsArrayBuffer(file);
      }
    },
  };

  useEffect(() => { 
    dispatch(listOfferCodesAction(id + ""));
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center mb-10 mt-10 justify-start">
      <div
        className={`w-[90%] ${
          !isMax1110 ? "h-[30rem]" : "h-[35rem]"
        } bg-white flex flex-col justify-start items-between shadow-md rounded-lg`}
      >
        <div className="w-full h-[4rem] px-4 bg-[#F9FAFB] border border-[#E6E8EC] flex flex-row items-end justify-between rounded-tr-lg rounded-tl-lg">
          {!isMax1110 && (
            <div className="h-[4rem] flex items-center justify-center gap-x-3">
              <Input
                prefix={<SearchOutlined />}
                className="!h-[2.5rem] !w-[18rem]"
                placeholder="Search by id, name, or email"
              />
              <button
                onClick={() => {
                  // dispatch({ type: SET_MERCHANT_ID, payload: null });
                  // navigate(CREATE_OFFER_PATH);
                  setModalOpen(true);
                }}
                className="px-6 py-4 w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-[--primary]"
              >
                <UploadOutlined className="font-bold text-white text-xl" />
              </button>
              <button
                onClick={() => {
                  // dispatch({ type: SET_MERCHANT_ID, payload: null });
                  // navigate(CREATE_OFFER_PATH);
                  setGenerateModalOpen(true);
                }}
                className="px-6 py-4 w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-[--primary]"
              >
                <PlusOutlined className="font-bold text-white" />
              </button>
            </div>
          )}
        </div>
        <Table
          className="m-4 shadow-md overflow-x-auto"
          dataSource={codes}
          loading={codesLoading || codeLoading}
          columns={OfferCodeTableCols((value: any) => {
            setModalOpen(true);
            setDeletingId(value);
          }, async (value: any) => {
            await dispatch(getOfferCodeAction(value));
            navigate(`/dashboard/offer-codes/update/${value}`);
          })}
        />
        <Modal
          title="Generate Offer Codes"
          open={generateModalOpen}
          onCancel={() => setGenerateModalOpen(false)}
          okButtonProps={{
            hidden: true,
          }}
          cancelButtonProps={{
            hidden: true,
          }}
        >
          <form
            className="w-full flex flex-col items-start gap-y-6"
            onSubmit={generateCodesFormik.handleSubmit}
          >
            <div className="w-full flex flex-col items-start">
              <label>Offer</label>
              <Select
                options={offersDropdown}
                onSelect={(value) => {
                  generateCodesFormik.setFieldValue("offer_id", value);
                }}
                value={generateCodesFormik.values.offer_id}
                className="w-full"
                placeholder="Offer"
                disabled
              />
              {uploadCodesFormik.errors.offer_id && (
                <p className="text-[red]">
                  {uploadCodesFormik.errors.offer_id}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col items-start">
              <label>Count</label>
              <Input
                onChange={generateCodesFormik.handleChange}
                className="w-full !h-[2.5rem]"
                name="count"
                placeholder="Count"
              />
              {generateCodesFormik.errors.count && (
                <p className="text-[red]">{generateCodesFormik.errors.count}</p>
              )}
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                type="submit"
                className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
              >
                {generateLoading ? (
                  <Loader />
                ) : (
                  <p className="text-white font-bold">Generate</p>
                )}
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          title="Upload Offer Codes"
          open={modalOpen}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{
            hidden: true,
          }}
          cancelButtonProps={{
            hidden: true,
          }}
        >
          <form
            className="w-full flex flex-col items-start gap-y-6"
            onSubmit={uploadCodesFormik.handleSubmit}
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Please upload only files of extension .xlsx, or .xls only with
                the codes in sheet 1, with a column named codes
              </p>
            </Dragger>
            <div className="w-full flex flex-col items-start">
              <label>Offer</label>
              <Select
                options={offersDropdown}
                onSelect={(value) => {
                  uploadCodesFormik.setFieldValue("offer_id", value);
                }}
                value={uploadCodesFormik.values.offer_id}
                className="w-full"
                placeholder="Offer"
                disabled
              />
              {uploadCodesFormik.errors.offer_id && (
                <p className="text-[red]">
                  {uploadCodesFormik.errors.offer_id}
                </p>
              )}
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                type="submit"
                className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
              >
                {uploadLoading ? (
                  <Loader />
                ) : (
                  <p className="text-white font-bold">Upload</p>
                )}
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default ViewOffer;
