import
{
    UPDATE_USER_LOADING,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    user: null,
    errors: null
}

export const updateUserReducer = (state =  initialState, action: any) => {
    switch (action.type) {
        case UPDATE_USER_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_USER_SUCCESS :
            return {...state, user: action.payload.data};
        case UPDATE_USER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}