import {
  LOGIN_PATH,
  DASHBOARD_PATH,
  USERS_PATH,
  MERCHANTS_PATH,
  CHANNELS_PATH,
  CREATE_USER_PATH,
  UPDATE_USER_PATH,
  CREATE_MERCHANT_PATH,
  UPDATE_MERCHANT_PATH,
  CREATE_CHANNEL_PATH,
  UPDATE_CHANNEL_PATH,
  CREATE_CATEGORY_PATH,
  UPDATE_CATEGORY_PATH,
  CREATE_SUB_CATEGORY_PATH,
  UPDATE_SUB_CATEGORY_PATH,
  CREATE_CLASSIFICATION_PATH,
  UPDATE_CLASSIFICATION_PATH,
  CREATE_SUBCLASSIFICATION_PATH,
  UPDATE_SUBCLASSIFICATION_PATH,
  PROFILE_PATH,
  CREATE_CHANNEL_MERCHANT_PATH,
  CHANNEL_MERCHANT_PATH,
  MERCHANT_CHANNEL_PATH,
  OFFERS_PATH,
  CREATE_OFFER_PATH,
  UPDATE_OFFER_PATH,
  CREATE_CHANNEL_OFFER_PATH,
  OFFER_CODES_PATH,
  VIEW_OFFER_PATH,
  UPDATE_OFFER_CODE_PATH,
  VOUCHERS_PATH,
  CREATE_VOUCHER_PATH,
  HOME_PATH,
} from "Routes/paths";
import Login from "screens/Login/Login";
import ProtectedRoutes from "./ProtectedRoutes";
import Dashboard from "screens/Dashboard/Dashboard";
import Users from "screens/Dashboard/StlrAdmin/Users/Users";
import Merchants from "screens/Dashboard/StlrAdmin/Merchants/Merchants";
import Channels from "screens/Dashboard/StlrAdmin/Channels/Channels";
import CreateUser from "screens/Dashboard/StlrAdmin/Users/CreateUser";
import UpdateUser from "screens/Dashboard/StlrAdmin/Users/UpdateUser";
import CreateMerchant from "screens/Dashboard/StlrAdmin/Merchants/CreateMerchant";
import UpdateMerchant from "screens/Dashboard/StlrAdmin/Merchants/UpdateMerchant";
import CreateChannel from "screens/Dashboard/StlrAdmin/Channels/CreateChannel";
import UpdateChannel from "screens/Dashboard/StlrAdmin/Channels/UpdateChannel";
import CreateCategory from "screens/Dashboard/StlrAdmin/Categories/CreateCategory";
import UpdateCategory from "screens/Dashboard/StlrAdmin/Categories/UpdateCategory";
import CreateSubCategory from "screens/Dashboard/StlrAdmin/SubCategories/CreateSubCategory";
import UpdateSubCategory from "screens/Dashboard/StlrAdmin/SubCategories/UpdateSubCategory";
import CreateClassification from "screens/Dashboard/StlrAdmin/Classifications/CreateClassification";
import UpdateClassification from "screens/Dashboard/StlrAdmin/Classifications/UpdateClassification";
import CreateSubClassification from "screens/Dashboard/StlrAdmin/SubClassifications/CreateSubClassification";
import UpdateSubClassification from "screens/Dashboard/StlrAdmin/SubClassifications/UpdateSubClassification";
import Profile from "screens/Dashboard/Profile/Profile";
import CreateChannelMerchant from "screens/Dashboard/StlrAdmin/ChannelMerchants/CreateChannelMerchant";
import ChannelMerchant from "screens/Dashboard/StlrAdmin/ChannelMerchants/ChannelMerchant";
import MerchantChannel from "screens/Dashboard/StlrAdmin/MerchantChannels/MerchantChannel";
import Offers from "screens/Dashboard/StlrAdmin/Offers/Offers";
import CreateOffer from "screens/Dashboard/StlrAdmin/Offers/CreateOffer";
import UpdateOffer from "screens/Dashboard/StlrAdmin/Offers/UpdateOffer";
import CreateChannelOffer from "screens/Dashboard/StlrAdmin/ChannelOffers/CreateChannelOffer";
import OfferCodes from "screens/Dashboard/StlrAdmin/OfferCodes/OfferCodes";
import ViewOffer from "screens/Dashboard/StlrAdmin/Offers/ViewOffer";
import UpdateOfferCode from "screens/Dashboard/StlrAdmin/OfferCodes/UpdateOfferCode";
import Vouchers from "screens/Dashboard/StlrAdmin/Vouchers/Vouchers";
import CreateVoucher from "screens/Dashboard/StlrAdmin/Vouchers/CreateVoucher";
import Home from "screens/Home/Home";

type RoutesType = {
  path: string;
  element: any;
  parent?: any;
};

export const routes: RoutesType[] = [
  {
    path: HOME_PATH,
    element: <Home />,
  },
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: DASHBOARD_PATH,
    element: <Dashboard />,
    parent: <ProtectedRoutes />,
  },
  {
    path: USERS_PATH,
    element: <Users />,
    parent: <ProtectedRoutes />,
  },
  {
    path: MERCHANTS_PATH,
    element: <Merchants />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CHANNELS_PATH,
    element: <Channels />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_USER_PATH,
    element: <CreateUser />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_USER_PATH,
    element: <UpdateUser />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_MERCHANT_PATH,
    element: <CreateMerchant />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_MERCHANT_PATH,
    element: <UpdateMerchant />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_CHANNEL_PATH,
    element: <CreateChannel />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_CHANNEL_PATH,
    element: <UpdateChannel />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_CATEGORY_PATH,
    element: <CreateCategory />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_CATEGORY_PATH,
    element: <UpdateCategory />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_SUB_CATEGORY_PATH,
    element: <CreateSubCategory />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_SUB_CATEGORY_PATH,
    element: <UpdateSubCategory />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_CLASSIFICATION_PATH,
    element: <CreateClassification />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_CLASSIFICATION_PATH,
    element: <UpdateClassification />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_SUBCLASSIFICATION_PATH,
    element: <CreateSubClassification />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_SUBCLASSIFICATION_PATH,
    element: <UpdateSubClassification />,
    parent: <ProtectedRoutes />,
  },
  {
    path: PROFILE_PATH,
    element: <Profile />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_CHANNEL_MERCHANT_PATH,
    element: <CreateChannelMerchant />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CHANNEL_MERCHANT_PATH,
    element: <ChannelMerchant />,
    parent: <ProtectedRoutes />,
  },
  {
    path: MERCHANT_CHANNEL_PATH,
    element: <MerchantChannel />,
    parent: <ProtectedRoutes />,
  },
  {
    path: OFFERS_PATH,
    element: <Offers />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_OFFER_PATH,
    element: <CreateOffer />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_OFFER_PATH,
    element: <UpdateOffer />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_CHANNEL_OFFER_PATH,
    element: <CreateChannelOffer />,
    parent: <ProtectedRoutes />,
  },
  {
    path: OFFER_CODES_PATH,
    element: <OfferCodes />,
    parent: <ProtectedRoutes />,
  },
  {
    path: VIEW_OFFER_PATH,
    element: <ViewOffer />,
    parent: <ProtectedRoutes />,
  },
  {
    path: UPDATE_OFFER_CODE_PATH,
    element: <UpdateOfferCode />,
    parent: <ProtectedRoutes />,
  },
  {
    path: VOUCHERS_PATH,
    element: <Vouchers />,
    parent: <ProtectedRoutes />,
  },
  {
    path: CREATE_VOUCHER_PATH,
    element: <CreateVoucher />,
    parent: <ProtectedRoutes />,
  },
];
