import
{
    LIST_CHANNELS_LOADING,
    LIST_CHANNELS_SUCCESS,
    LIST_CHANNELS_FAILURE
}
from "redux/types";

const initialState = {
    channelsLoading: false,
    channels: null,
    errors: null
}

export const listChannelsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_CHANNELS_LOADING :
            return {...state, channelsLoading: action.payload};
        case LIST_CHANNELS_SUCCESS :
            return {...state, channels: action.payload.data};
        case LIST_CHANNELS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}