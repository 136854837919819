import { DatePicker, Input, Select, Spin } from "antd";
import Loader from "components/Loader/Loader";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as yup from "yup";
import CreateVoucherModel from "models/CreateVoucherModel";
import { RootState } from "redux/rootReducer";
import { useDropdown } from "hooks/useDropdown";

const CreateVoucher = () => {
  const formik = useFormik({
    initialValues: {
      merchant_id: "",
      channel_id: "",
      voucher_type: "",
      voucher: 0,
      start_date: "",
      end_date: "",
      pointsRequired: 0,
      voucher_status: "",
    },
    validationSchema: yup.object({
      merchant_id: yup.string().required("please provide this field"),
      channel_id: yup.string().required("please provide this field"),
      voucher_type: yup.string().required("please provide this field"),
      voucher: yup.number().required("please provide this field"),
      start_date: yup.string(),
      end_date: yup.string(),
      pointsRequired: yup.number().required("please provide this field"),
      voucher_status: yup.string(),
    }),
    onSubmit: (values: CreateVoucherModel) => {
      console.log(values);
    },
  });

  const { createLoading } = useSelector(
    (state: RootState) => state.createVoucherReducer
  );

  const {
    merchantsDropdown,
    channelsDropdown,
    voucherTypesDropdown,
    voucherStatusDropdown,
  } = useDropdown();

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Create Voucher</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Merchant</label>
              <Select
                options={merchantsDropdown}
                onSelect={(value) => {
                  formik.setFieldValue("merchant_id", value);
                }}
                className="w-full"
                placeholder="Merchant"
              />
              {formik.errors.merchant_id && (
                <p className="text-[red]">{formik.errors.merchant_id}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Channel</label>
              <Select
                options={channelsDropdown}
                onSelect={(value) => {
                  formik.setFieldValue("channel_id", value);
                }}
                className="w-full"
                placeholder="Channel"
              />
              {formik.errors.channel_id && (
                <p className="text-[red]">please provide this field</p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Voucher Type</label>
              <Select
                options={voucherTypesDropdown}
                onSelect={(value) => {
                  formik.setFieldValue("voucher_type", value);
                }}
                className="w-full"
                placeholder="Voucher Type"
              />
              {formik.errors.voucher_type && (
                <p className="text-[red]">{formik.errors.voucher_type}</p>
              )}
            </div>
            <div className="select-spin-container w-[50%] flex flex-col items-start">
              <label>Voucher</label>
              <Input
                onChange={formik.handleChange}
                className="w-full !h-[2.5rem]"
                name="voucher"
                placeholder="Voucher"
              />
              {formik.errors.voucher && (
                <p className="text-[red]">please provide this field</p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Start Date</label>
              <DatePicker
                className="!h-[2.5rem] w-full"
                onChange={(date, dateString) => {
                  formik.setFieldValue("start_date", dateString);
                }}
              />
              {formik.errors.start_date && (
                <p className="text-[red]">{formik.errors.start_date}</p>
              )}
            </div>
            <div className="select-spin-container w-[50%] flex flex-col items-start">
              <label>End Date</label>
              <DatePicker
                className="!h-[2.5rem] w-full"
                onChange={(date, dateString) => {
                  formik.setFieldValue("end_date", dateString);
                }}
              />
              {formik.errors.end_date && (
                <p className="text-[red]">{formik.errors.end_date}</p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Points Required</label>
              <Input
                onChange={formik.handleChange}
                className="w-full !h-[2.5rem]"
                name="pointsRequired"
                placeholder="Points Required"
              />
              {formik.errors.pointsRequired && (
                <p className="text-[red]">{formik.errors.pointsRequired}</p>
              )}
            </div>
            <div className="select-spin-container w-[50%] flex flex-col items-start">
              <label>Voucher Status</label>
              <Select
                options={voucherStatusDropdown}
                onSelect={(value) => {
                  formik.setFieldValue("voucher_status", value);
                }}
                className="w-full"
                placeholder="Voucher Status"
              />
              {formik.errors.voucher_status && (
                <p className="text-[red]">{formik.errors.voucher_status}</p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {createLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Create</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateVoucher;
