import { Select } from "antd";
import { useFormik } from "formik";
import Loader from "components/Loader/Loader";
import * as yup from "yup";
import CreateChannelOfferModel from "models/CreateChannelOfferModel";
import { useDispatch } from "react-redux";
import { createChannelOfferAction } from "redux/actions/channelOffersActions/createChannelOfferAction";
import { useNavigate } from "react-router";
import { useDropdown } from "hooks/useDropdown";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";

const CreateChannelOffer = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { offersDropdown, channelsDropdown } = useDropdown();

  const { offer } = useSelector((state: RootState) => state.listSingleOfferReducer);

  const { createLoading } = useSelector((state: RootState) => state.createChannelOfferReducer);

  const formik = useFormik({
    initialValues: {
      offer_id: offer?.id,
      channel_id: "",
    },
    validationSchema: yup.object({
      offer_id: yup.string().required("please enter this field"),
      channel_id: yup.string().required("please enter this field"),
    }),
    onSubmit: async (values: CreateChannelOfferModel) => {
      await dispatch(
        createChannelOfferAction(
          {
            offer_id: values.offer_id,
            channel_id: values.channel_id,
          },
          () => navigate(-1)
        )
      );
    },
  });

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Link Channel to Offer</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Offer Id</label>
              <Select
                options={offersDropdown}
                value={formik.values.offer_id}
                onSelect={(value) => {
                  formik.setFieldValue("offer_id", value);
                }}
                disabled
                className="w-full"
                placeholder="Category"
              />
              {formik.errors.offer_id && (
                <p className="text-[red]">{formik.errors.offer_id}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Channel</label>
              <Select
                options={channelsDropdown}
                onSelect={(value) => {
                  formik.setFieldValue("channel_id", value);
                }}
                className="w-full"
                placeholder="Channel"
              />
              {formik.errors.channel_id && (
                <p className="text-[red]">{formik.errors.channel_id}</p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {createLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Link</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateChannelOffer;
