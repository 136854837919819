import
{
    GET_CLASSIFICATION_LOADING,
    GET_CLASSIFICATION_SUCCESS,
    GET_CLASSIFICATION_FAILURE
}
from "redux/types";

const initialState = {
    classificationLoading: false,
    classification: null,
    errors: null
}

export const listSingleClassificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_CLASSIFICATION_LOADING :
            return {...state, classificationLoading: action.payload};
        case GET_CLASSIFICATION_SUCCESS :
            return {...state, classification: action.payload.data};
        case GET_CLASSIFICATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}