export const HOME_PATH = "/";
export const LOGIN_PATH = "/login";
export const DASHBOARD_PATH = "/dashboard";


export const USERS_PATH = `${DASHBOARD_PATH}/users`;
export const CREATE_USER_PATH = `${USERS_PATH}/create`;
export const UPDATE_USER_PATH = `${USERS_PATH}/update/:uuid`;

export const PROFILE_PATH = `${DASHBOARD_PATH}/profile`;

export const MERCHANTS_PATH = `${DASHBOARD_PATH}/merchants`;
export const CREATE_MERCHANT_PATH = `${MERCHANTS_PATH}/create`;
export const UPDATE_MERCHANT_PATH = `${MERCHANTS_PATH}/update/:id`;

export const CHANNELS_PATH = `${DASHBOARD_PATH}/channels`;
export const CREATE_CHANNEL_PATH = `${CHANNELS_PATH}/create`;
export const UPDATE_CHANNEL_PATH = `${CHANNELS_PATH}/update/:id`;

export const CREATE_CHANNEL_MERCHANT_PATH = `${CHANNELS_PATH}/link`;

export const CHANNEL_MERCHANT_PATH = `${DASHBOARD_PATH}/channel-merchant/:id`;

export const MERCHANT_CHANNEL_PATH = `${DASHBOARD_PATH}/merchant-channel/:id`;

export const CATEGORIES_PATH = `${DASHBOARD_PATH}/categories`;
export const CREATE_CATEGORY_PATH = `${CATEGORIES_PATH}/create`;
export const UPDATE_CATEGORY_PATH = `${CATEGORIES_PATH}/update/:id`;

export const SUB_CATEGORIES_PATH = `${DASHBOARD_PATH}/sub-categories`;
export const CREATE_SUB_CATEGORY_PATH = `${SUB_CATEGORIES_PATH}/create`;
export const UPDATE_SUB_CATEGORY_PATH = `${SUB_CATEGORIES_PATH}/update/:id`;

export const CLASSIFICATIONS_PATH = `${DASHBOARD_PATH}/classifications`;
export const CREATE_CLASSIFICATION_PATH = `${CLASSIFICATIONS_PATH}/create`;
export const UPDATE_CLASSIFICATION_PATH = `${CLASSIFICATIONS_PATH}/update/:id`;

export const SUBCLASSIFICATIONS_PATH = `${DASHBOARD_PATH}/sub-classifications`;
export const CREATE_SUBCLASSIFICATION_PATH = `${SUBCLASSIFICATIONS_PATH}/create`;
export const UPDATE_SUBCLASSIFICATION_PATH = `${SUBCLASSIFICATIONS_PATH}/update/:id`;

export const OFFERS_PATH = `${DASHBOARD_PATH}/offers`;
export const CREATE_OFFER_PATH = `${OFFERS_PATH}/create`;
export const UPDATE_OFFER_PATH = `${OFFERS_PATH}/update/:id`;
export const VIEW_OFFER_PATH = `${OFFERS_PATH}/view/:id`;

export const CREATE_CHANNEL_OFFER_PATH = `${DASHBOARD_PATH}/channel-offer/create`;

export const OFFER_CODES_PATH = `${DASHBOARD_PATH}/offer-codes`;
export const UPDATE_OFFER_CODE_PATH = `${DASHBOARD_PATH}/offer-codes/update/:id`;

export const VOUCHERS_PATH = `${DASHBOARD_PATH}/vouchers`;
export const CREATE_VOUCHER_PATH = `${VOUCHERS_PATH}/create`;