import { Input, Select } from "antd";
import Loader from "components/Loader/Loader";
import { useFormik } from "formik";
import CreateSubClassificationModel from "models/CreateSubClassificationModel";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { updateSubClassificationAction } from "redux/actions/subClassificationsActions/updateSubClassificationAction";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDropdown } from "hooks/useDropdown";
import { useNavigate } from "react-router";

const UpdateSubClassification = () => {

    const { updateLoading } = useSelector((state: RootState) => state.updateSubClassificationReducer);
    const { subClassification } = useSelector((state: RootState) => state.listSingleSubClassificationReducer);

    const { classificationsDropdown } = useDropdown();

    const navigate = useNavigate();

    const dispatch: any = useDispatch();

    const formik = useFormik({
        initialValues: {
          name: subClassification?.name,
          classification_id: subClassification?.classification_id,
        },
        validationSchema: yup.object({
          name: yup.string().required("please enter this field"),
          classification_id: yup.string().required("please enter this field"),
        }),
        onSubmit: async (values: CreateSubClassificationModel) => {
          await dispatch(
            updateSubClassificationAction(
                subClassification?.id,
              {
                name: values.name,
                classification_id: values.classification_id,
              },
              () => navigate(-1)
            )
          );
        },
      });

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Update Sub Classification</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Name</label>
              <Input
                placeholder="Name"
                name="name"
                value={formik.values.name}
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <p className="text-[red]">{formik.errors.name}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Classification</label>
              <Select
                options={classificationsDropdown}
                onSelect={(value) => {
                  formik.setFieldValue("classification_id", value);
                }}
                value={formik.values.classification_id}
                className="w-full"
                placeholder="Classification"
              />
              {formik.errors.classification_id && (
                <p className="text-[red]">{formik.errors.classification_id}</p>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {updateLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Update</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateSubClassification;
