import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const VouchersTableCols = (handleDelete: any, handleUpdate: any) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Merchant",
    dataIndex: "merchant",
    key: "merchant",
    render: (value: any) => {
      return <p>{value?.name}</p>;
    },
  },
  {
    title: "Points Required",
    dataIndex: "pointsRequired",
    key: "pointsRequired",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Voucher Type",
    dataIndex: "voucher_type",
    key: "voucher_type",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Voucher",
    dataIndex: "voucher",
    key: "voucher",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
    render: (value: any) => {
      return <p>{value?.split(" ")[0]}</p>;
    },
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
    render: (value: any) => {
      return <p>{value === null ? "No Date" : value?.split(" ")[0]}</p>;
    },
  },
  {
    title: "Status",
    dataIndex: "voucher_status",
    key: "voucher_status",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Actions",
    dataIndex: "id",
    key: "id",
    render: (value: any) => {
      return (
        <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
          <DeleteOutlined
            className="text-[red]"
            onClick={() => handleDelete(value)}
          />
          <EditOutlined
            className="text-blue-600"
            onClick={() => handleUpdate(value)}
          />
        </div>
      );
    },
  },
];

export default VouchersTableCols;
