import api from "api";
import store from "redux/store";

export const updateItemService = async (url: string, id: string, data:any) => {
    const response = await api.post(
        url + id,
        data,
        {
            headers: {
                "Content-Type" : "multipart/form-data",
                "Authorization" : `Bearer ${store.getState()?.loginReducer?.token}`
            }
        }
    );

    return response;
}