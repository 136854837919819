import
{
    UPDATE_OFFER_CODE_LOADING,
    UPDATE_OFFER_CODE_SUCCESS,
    UPDATE_OFFER_CODE_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    success: null,
    errors: null
}

export const updateOfferCodeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_OFFER_CODE_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_OFFER_CODE_SUCCESS :
            return {...state, success: action.payload.success};
        case UPDATE_OFFER_CODE_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}