import
{
    LIST_OFFERS_LOADING,
    LIST_OFFERS_SUCCESS,
    LIST_OFFERS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listOffersAction = (merchantId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_OFFERS_LOADING, payload: true});
        const response = await listItemsService(`/api/stlr/offer${merchantId ? `?merchant_id=${merchantId}` : ''}`);
        dispatch({type: LIST_OFFERS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_OFFERS_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_OFFERS_LOADING, payload: false});
    }
}