import
{
    CREATE_CHANNEL_OFFER_LOADING,
    CREATE_CHANNEL_OFFER_SUCCESS,
    CREATE_CHANNEL_OFFER_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const createChannelOfferAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: CREATE_CHANNEL_OFFER_LOADING, payload: true});
        const response = await createNewItemService('/api/stlr/channelOffer', data);
        dispatch({type: CREATE_CHANNEL_OFFER_SUCCESS, payload: response.data});
        callback && callback();
    } catch (err) {
        dispatch({type: CREATE_CHANNEL_OFFER_FAILURE, payload: err});
    } finally {
        dispatch({type: CREATE_CHANNEL_OFFER_LOADING, payload: false});
    }
}