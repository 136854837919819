import
{
    GET_SUBCATEGORY_LOADING,
    GET_SUBCATEGORY_SUCCESS,
    GET_SUBCATEGORY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const listSingleSubCategoryAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: GET_SUBCATEGORY_LOADING, payload: true});
        const response = await listSingleItemService('/api/stlr/subCategory/', id);
        dispatch({type: GET_SUBCATEGORY_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: GET_SUBCATEGORY_FAILURE, payload: err});
    } finally {
        dispatch({type: GET_SUBCATEGORY_LOADING, payload: false});
    }
}