import
{
    CREATE_SUBCLASSIFICATION_LOADING,
    CREATE_SUBCLASSIFICATION_SUCCESS,
    CREATE_SUBCLASSIFICATION_FAILURE
}
from "redux/types";

const initialState = {
    createLoading: false,
    newSubClassification: null,
    errors: null
}

export const createSubClassificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_SUBCLASSIFICATION_LOADING :
            return {...state, createLoading: action.payload};
        case CREATE_SUBCLASSIFICATION_SUCCESS :
            return {...state, newSubClassification: action.payload.data};
        case CREATE_SUBCLASSIFICATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}