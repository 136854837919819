import styles from "components/Navbar/Navbar.module.css";
import { WhiteStlrLogo } from "assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { Dropdown, MenuProps } from "antd";
import { CloseOutlined, DownOutlined, MenuOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useResponsive } from "hooks/useResponsive";
import { useEffect, useState } from "react";
import {
  MERCHANT_ADMIN_SIDE_BAR_ITEMS,
  STLR_ADMIN_SIDE_BAR_ITEMS,
} from "contstants/SideBarItems";
import { useNavigate } from "react-router";
import { PROFILE_PATH } from "Routes/paths";

const Navbar = () => {
  const { user } = useSelector((state: RootState) => state.loginReducer);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [sideBarItems, setSideBarItems] = useState<any[]>();
  const [selectedItem, setSelectedItem] = useState("");

  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const { isMax1110 } = useResponsive();

  const highlightSidebarItem = () => {
    switch (window.location.pathname) {
      case "/dashboard/users":
        setSelectedItem("Users");
        return;
      case "/dashboard/merchants":
        setSelectedItem("Merchants");
        return;
      case "/dashboard/channels":
        setSelectedItem("Channels");
        return;
      default:
        return;
    }
  };

  const getUserItems = () => {
    switch (user?.role) {
      case "ADMIN":
        return STLR_ADMIN_SIDE_BAR_ITEMS;
      case "MERCHANT_ADMIN":
        return MERCHANT_ADMIN_SIDE_BAR_ITEMS;
      default:
        return [];
    }
  };

  useEffect(() => {
    setSideBarItems(getUserItems());
  }, []);

  useEffect(() => {
    highlightSidebarItem();
  }, [window.location.pathname]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            navigate(PROFILE_PATH);
            setDrawerOpen(false);
          }}
        >
          Profile
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            dispatch({ type: "LOG_OUT" });
            setDrawerOpen(false);
          }}
        >
          Log Out
        </a>
      ),
    },
  ];

  return (
    <>
      <div className="w-full min-h-[80px] h-[80px] bg-[--primary] flex flex-row items-center justify-between px-6 border-b border-[#EAECF0]">
        {!isMax1110 ? (
          <img src={WhiteStlrLogo} className="h-[2.5rem]" />
        ) : drawerOpen ? (
          <CloseOutlined
            onClick={() => setDrawerOpen((prev) => !prev)}
            className="text-white text-2xl"
          />
        ) : (
          <MenuOutlined
            onClick={() => setDrawerOpen((prev) => !prev)}
            className="text-white text-2xl"
          />
        )}
        {user && !isMax1110 && (
          <Dropdown menu={{ items }}>
            <div className="flex flex-row items-center gap-x-4">
              <div className="flex flex-col items-start">
                <p className="font-bold text-white">{user?.name}</p>
                <p className="text-gray-400">{user?.role?.toLowerCase()}</p>
              </div>
              <DownOutlined className="text-gray-400" />
            </div>
          </Dropdown>
        )}
        {isMax1110 && <img src={WhiteStlrLogo} className="h-[2.5rem]" />}
      </div>
      <div
        className={`${
          drawerOpen
            ? "w-[15rem] h-full transition-all bg-[--primary]"
            : "w-0 h-full transition-all"
        } absolute left-0 top-[70px] z-50 flex flex-col gap-y-5 pt-10`}
      >
        {drawerOpen && (
          <div className="w-full flex items-center px-6 mb-4">
            <Dropdown menu={{ items }}>
              <div className="flex flex-row items-center gap-x-4">
                <div className="flex flex-col items-start">
                  <p className="font-bold text-white">{user?.name}</p>
                  <p className="text-gray-400">{user?.role?.toLowerCase()}</p>
                </div>
                <DownOutlined className="text-gray-400" />
              </div>
            </Dropdown>
          </div>
        )}
        {drawerOpen &&
          sideBarItems?.map((item: any, idx: number) => {
            return (
              <div
                key={idx}
                onClick={() => {
                  navigate(item.route);
                  setDrawerOpen(false);
                }}
                className={`w-[85%] h-[44px] px-4 flex flex-row items-center justify-start gap-x-6 rounded-md transition-all ${
                  selectedItem === item.name ? "bg-white translate-x-2" : ""
                } hover:cursor-pointer`}
              >
                <img
                  src={
                    selectedItem === item.name ? item.selectedIcon : item.icon
                  }
                  className="w-[1.5rem] h-[1.5rem]"
                />
                <p
                  className={
                    selectedItem === item.name
                      ? "text-black font-bold"
                      : "text-white"
                  }
                >
                  {item.name}
                </p>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Navbar;
