import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Modal, Table, Tabs, TabsProps, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "redux/rootReducer";
import {
  CREATE_CHANNEL_PATH,
  CREATE_CLASSIFICATION_PATH,
  CREATE_SUBCLASSIFICATION_PATH,
} from "Routes/paths";
import { useResponsive } from "hooks/useResponsive";
import { listChannelsAction } from "redux/actions/channelsActions/listChannelsAction";
import ChannelTableCols from "models/tableColumns/ChannelTableCols";
import { deleteChannelAction } from "redux/actions/channelsActions/deleteChannelAction";
import { listSingleChannelAction } from "redux/actions/channelsActions/listSingleChannelAction";
import ClassificationTableCols from "models/tableColumns/ClassificationTableCols";
import { listClassificationsAction } from "redux/actions/classificationsActions/listClassificationsAction";
import { listSingleClassificationsAction } from "redux/actions/classificationsActions/listSingleClassificationAction";
import { deleteClassificationAction } from "redux/actions/classificationsActions/deleteClassificationAction";
import { listSubClassificationsAction } from "redux/actions/subClassificationsActions/listSubClassificationsAction";
import SubClassificationTableCols from "models/tableColumns/SubClassificationTableCols";
import { listSingleSubClassificationAction } from "redux/actions/subClassificationsActions/listSingleSubClassificationAction";
import { deleteSubClassificationAction } from "redux/actions/subClassificationsActions/deleteSubClassificationAction";
import { SET_CHANNEL_MERCHANT_ID } from "redux/types";
import { listSingleMerchantAction } from "redux/actions/merchantsActions/listSingleMerchantAction";

const Channels = () => {
  const { user } = useSelector((state: RootState) => state.loginReducer);

  const { channelsLoading, channels } = useSelector(
    (state: RootState) => state.listChannelsReducer
  );
  const { deleteLoading } = useSelector(
    (state: RootState) => state.deleteChannelReducer
  );
  const { channelLoading } = useSelector(
    (state: RootState) => state.listSingleChannelReducer
  );

  const { classificationsLoading, classifications } = useSelector(
    (state: RootState) => state.listClassificationsReducer
  );
  const { classificationLoading } = useSelector(
    (state: RootState) => state.listSingleClassificationReducer
  );
  const { deleteClassificationLoading } = useSelector(
    (state: RootState) => state.deleteClassificationReducer
  );

  const { subClassificationsLoading, subClassifications } = useSelector(
    (state: RootState) => state.listSubClassificationsReducer
  );
  const { subClassificationLoading } = useSelector(
    (state: RootState) => state.listSingleSubClassificationReducer
  );
  const { deleteSubClassificationLoading } = useSelector(
    (state: RootState) => state.deleteSubClassificationReducer
  );

  const { merchant } = useSelector(
    (state: RootState) => state.listSingleMerchantReducer
  );

  const [modalOpen, setModalOpen] = useState(false);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [image, setImage] = useState("");

  const [deletingId, setDeletingId] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("channels");

  const [search, setSearch] = useState("");

  const { isMax1110 } = useResponsive();

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const uniqueCategories: { [name: string]: boolean } = {};

  // Step 2: Iterate through subCategories and populate uniqueCategories
  merchant?.subCategories?.forEach((subCategory: any) => {
    const categoryName = subCategory.category.name;
    uniqueCategories[categoryName] = true;
  });

  // Step 3: Extract unique category names from uniqueCategories object
  const uniqueCategoryNames = Object.keys(uniqueCategories);

  const adminItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Channels",
      children: <></>,
    },
    {
      key: "2",
      label: "Classifications",
      children: <></>,
    },
    {
      key: "3",
      label: "Sub Classifications",
      children: <></>,
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Channels",
      children: <></>,
    },
  ];

  useEffect(() => {
    dispatch(listChannelsAction());
    user?.role === "MERCHANT_ADMIN" &&
      dispatch(listSingleMerchantAction(user?.merchant?.uuid));
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (user?.role !== "ADMIN" && user?.role !== "MERCHANT_ADMIN") {
      dispatch({ type: "LOG_OUT" });
    }
  }, [user]);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-y-3">
      {isMax1110 && (
        <div
          onClick={() => {
            if (selectedCategory === "channels") {
              navigate(CREATE_CHANNEL_PATH);
            } else if (selectedCategory === "classifications") {
              navigate(CREATE_CLASSIFICATION_PATH);
            } else if (selectedCategory === "sub_classifications") {
              navigate(CREATE_SUBCLASSIFICATION_PATH);
            }
          }}
          className="w-[3rem] h-[3rem] absolute bottom-10 right-10 bg-[--primary] rounded-full flex items-center justify-center"
        >
          <PlusOutlined className="text-white text-2xl" />
        </div>
      )}

      {isMax1110 && (
        <Input
          prefix={<SearchOutlined />}
          className="!h-[2.5rem] !w-[90%]"
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder="Search by id, name, or email"
        />
      )}

      <div className="w-[90%] flex flex-col items-start">
        {user?.role === "MERCHANT_ADMIN" && (
          <>
            <div className="w-full flex items-center justify-start gap-x-4">
              <h1 className="text-4xl font-bold">{merchant?.name}</h1>
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}/public/uploads/merchantLogos/${merchant?.logo}`}
                className="w-[4rem] h-[4rem] rounded-full object-cover"
                alt="logo"
              />
            </div>
            <p className="text-xl font-bold">Categories</p>
            <div className="flex flex-row items-center gap-x-2 ml-5 flex-wrap">
              {uniqueCategoryNames?.length === 0 ? (
                <p>No Items</p>
              ) : (
                uniqueCategoryNames?.map((item: any) => {
                  return (
                    <Tag color="blue" key={item}>
                      <p>{item}</p>
                    </Tag>
                  );
                })
              )}
            </div>

            <p className="font-bold text-xl mt-2">Sub Categories</p>
            <div className="flex flex-row items-center gap-x-2 ml-5 flex-wrap">
              {merchant?.subCategories?.length === 0 ? (
                <p>No Items</p>
              ) : (
                merchant?.subCategories?.map((item: any) => {
                  return (
                    <Tag color="blue" key={item}>
                      <p>{item?.name}</p>
                    </Tag>
                  );
                })
              )}
            </div>
          </>
        )}
      </div>

      <div
        className={`w-[90%] ${
          !isMax1110 ? "h-[30rem]" : "h-[35rem]"
        } bg-white flex flex-col justify-start items-between shadow-md rounded-lg`}
      >
        <div className="w-full h-[4rem] px-4 bg-[#F9FAFB] border border-[#E6E8EC] flex flex-row items-end justify-between rounded-tr-lg rounded-tl-lg">
          <Tabs
            defaultActiveKey="1"
            items={user?.role === "ADMIN" ? adminItems : items}
            onChange={(value) => {
              if (value === "1") {
                setSelectedCategory("channels");
                dispatch(listChannelsAction());
              } else if (value === "2") {
                setSelectedCategory("classifications");
                dispatch(listClassificationsAction());
              } else if (value === "3") {
                setSelectedCategory("sub_classifications");
                dispatch(listSubClassificationsAction());
              }
            }}
          />
          {!isMax1110 && user?.role === "ADMIN" && (
            <div className="h-[4rem] flex items-center justify-center gap-x-3">
              <Input
                prefix={<SearchOutlined />}
                className="!h-[2.5rem] !w-[18rem]"
                onChange={(e: any) => setSearch(e.target.value)}
                placeholder="Search by id, name, or email"
              />
              <button
                onClick={() => {
                  if (selectedCategory === "channels") {
                    navigate(CREATE_CHANNEL_PATH);
                  } else if (selectedCategory === "classifications") {
                    navigate(CREATE_CLASSIFICATION_PATH);
                  } else if (selectedCategory === "sub_classifications") {
                    navigate(CREATE_SUBCLASSIFICATION_PATH);
                  }
                }}
                className="px-6 py-4 w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-[--primary]"
              >
                <PlusOutlined className="font-bold text-white" />
              </button>
            </div>
          )}
        </div>

        {selectedCategory === "channels" && (
          <Table
            className="m-4 shadow-md overflow-x-auto"
            dataSource={channels?.filter((item: any) =>
              item.name.toLowerCase().includes(search.toLowerCase())
            )}
            loading={channelsLoading || deleteLoading || channelLoading}
            columns={ChannelTableCols(
              imageModalOpen,
              setImageModalOpen,
              image,
              setImage,
              user?.role === "ADMIN",
              (value: any) => {
                setModalOpen(true);
                setDeletingId(value);
              },
              async (value: any) => {
                await dispatch(listSingleChannelAction(value ?? ""));
                navigate(`/dashboard/channels/update/${value}`);
              },
              true,
              true,
              async (value: any) => {
                await dispatch(listSingleChannelAction(value ?? ""));
                navigate(`/dashboard/channels/link`);
              },
              async (value: any) => {
                dispatch({ type: SET_CHANNEL_MERCHANT_ID, payload: value });
                await dispatch(listSingleChannelAction(value));
                navigate(`/dashboard/channel-merchant/${value}`);
              },
              user?.role === "MERCHANT_ADMIN"
            )}
          />
        )}

        {selectedCategory === "classifications" && (
          <Table
            className="m-4 shadow-md overflow-x-auto"
            dataSource={classifications}
            loading={
              classificationsLoading ||
              deleteClassificationLoading ||
              classificationLoading
            }
            columns={ClassificationTableCols(
              (value: any) => {
                setModalOpen(true);
                setDeletingId(value);
              },
              async (value: any) => {
                await dispatch(listSingleClassificationsAction(value ?? ""));
                navigate(`/dashboard/classifications/update/${value}`);
              }
            )}
          />
        )}

        {selectedCategory === "sub_classifications" && (
          <Table
            className="m-4 shadow-md overflow-x-auto"
            dataSource={subClassifications}
            loading={
              subClassificationsLoading ||
              deleteSubClassificationLoading ||
              subClassificationLoading
            }
            columns={SubClassificationTableCols(
              (value: any) => {
                setModalOpen(true);
                setDeletingId(value);
              },
              async (value: any) => {
                await dispatch(listSingleSubClassificationAction(value ?? ""));
                navigate(`/dashboard/sub-classifications/update/${value}`);
              }
            )}
          />
        )}

        <Modal
          title="Delete Confirmation"
          open={modalOpen}
          onOk={async () => {
            if (selectedCategory === "channels") {
              await dispatch(deleteChannelAction(deletingId));
              setModalOpen(false);
              dispatch(listChannelsAction());
            } else if (selectedCategory === "classifications") {
              await dispatch(deleteClassificationAction(deletingId));
              setModalOpen(false);
              dispatch(listClassificationsAction());
            } else if (selectedCategory === "sub_classifications") {
              await dispatch(deleteSubClassificationAction(deletingId));
              setModalOpen(false);
              dispatch(listSubClassificationsAction());
            }
          }}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{
            className: "bg-[--primary]",
          }}
          confirmLoading={
            deleteLoading ||
            deleteClassificationLoading ||
            deleteSubClassificationLoading
          }
        >
          <p>Are you sure you want to delete: {deletingId}</p>
        </Modal>
      </div>
    </div>
  );
};

export default Channels;
