import
{
    DELETE_USER_LOADING,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE
}
from "redux/types";

const initialState = {
    deleteLoading: false,
    success: null,
    errors: null
};

export const deleteUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_USER_LOADING :
            return {...state, deleteLoading: action.payload};
        case DELETE_USER_SUCCESS :
            return {...state, success: action.payload.data.success};
        case DELETE_USER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}