import { DatePicker, Input, Select } from "antd";
import Loader from "components/Loader/Loader";
import { useFormik } from "formik";
import CreateOfferModel from "models/CreateOfferModel";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { updateOfferAction } from "redux/actions/offersActions/updateOfferAction";
import { RootState } from "redux/rootReducer";
import { useDropdown } from "hooks/useDropdown";
import * as yup from "yup";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const UpdateOffer = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { offer } = useSelector(
    (state: RootState) => state.listSingleOfferReducer
  );
  const { updateLoading } = useSelector(
    (state: RootState) => state.updateOfferReducer
  );

  const {
    merchantsDropdown,
    discountTypeDropdown,
    limitTypeDropdown,
    statusDropdown,
  } = useDropdown();

  const formik = useFormik({
    initialValues: {
      merchant_id: offer?.merchant_id,
      discount_type: offer.discount_type,
      discount: offer.discount,
      min_amount: offer.min_amount,
      max_amount: offer.max_amount,
      start_date: offer.start_date,
      end_date: offer.end_date,
      limit_type: offer.limit_type,
      starting_quota: offer.starting_quota,
      offer_status: offer.offer_status,
    },
    validationSchema: yup.object({
      merchant_id: yup.string().required("please enter this field"),
      discount_type: yup.string().required("please enter this field"),
      discount: yup.number().required("please enter this field"),
      min_amount: yup.number().required("please enter this field"),
      max_amount: yup.number().required("please enter this field"),
      start_date: yup.string().when("limit_type", ([type], sch) => {
        return type === "DURATION" || type === "BOTH"
          ? sch.required("please enter this field")
          : sch.optional();
      }),
      end_date: yup.string().when("limit_type", ([type], sch) => {
        return type === "DURATION" || type === "BOTH"
          ? sch.required("please enter this field")
          : sch.optional();
      }),
      limit_type: yup.string().required("please enter this field"),
      starting_quota: yup.number().when("limit_type", ([type], sch) => {
        return type === "QUOTA" || type === "BOTH"
          ? sch.required("please enter this field")
          : sch.optional();
      }),
    }),
    onSubmit: async (values: CreateOfferModel) => {
      await dispatch(
        updateOfferAction(
          offer.id,
          {
            merchant_id: values.merchant_id,
            discount_type: values.discount_type,
            discount: values.discount,
            min_amount: values.min_amount,
            max_amount: values.max_amount,
            start_date: values.start_date.split(" ")[0],
            end_date: values.end_date.split(" ")[0],
            limit_type: values.limit_type,
            starting_quota: values.starting_quota,
            offer_status: values.offer_status,
          },
          () => navigate(-1)
        )
      );
    },
  });

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Update Offer</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Merchant</label>
              <Select
                options={merchantsDropdown}
                value={formik.values.merchant_id}
                onSelect={(value) => {
                  formik.setFieldValue("merchant_id", value);
                }}
                className="w-full"
                placeholder="Merchant"
              />
              {formik.errors.merchant_id && (
                <p className="text-[red]">{formik.errors.merchant_id}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Limit Type</label>
              <Select
                options={limitTypeDropdown}
                value={formik.values.limit_type}
                onSelect={(value) => {
                  formik.setFieldValue("limit_type", value);
                }}
                className="w-full"
                placeholder="Limit Type"
              />
              {formik.errors.limit_type && (
                <p className="text-[red]">{formik.errors.limit_type}</p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Discount Type</label>
              <Select
                options={discountTypeDropdown}
                value={formik.values.discount_type}
                onSelect={(value) => {
                  formik.setFieldValue("discount_type", value);
                }}
                className="w-full"
                placeholder="Discount Type"
              />
              {formik.errors.discount_type && (
                <p className="text-[red]">{formik.errors.discount_type}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Discount</label>
              <Input
                placeholder="Discount"
                name="discount"
                value={formik.values.discount}
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.discount && (
                <p className="text-[red]">{formik.errors.discount}</p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Min Amount</label>
              <Input
                placeholder="Min Amount"
                value={formik.values.min_amount}
                name="min_amount"
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.min_amount && (
                <p className="text-[red]">{formik.errors.min_amount}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Max Amount</label>
              <Input
                placeholder="Max Amount"
                value={formik.values.max_amount}
                name="max_amount"
                className="!h-[2.5rem] w-full"
                disabled={
                  formik.values.limit_type !== "BOTH" &&
                  formik.values.limit_type !== "QUOTA"
                }
                onChange={formik.handleChange}
              />
              {formik.errors.max_amount && (
                <p className="text-[red]">{formik.errors.max_amount}</p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Start Date</label>
              <DatePicker
                className="!h-[2.5rem] w-full"
                value={dayjs(formik.values.start_date)}
                allowClear={false}
                disabled={
                  formik.values.limit_type !== "BOTH" &&
                  formik.values.limit_type !== "DURATION"
                }
                onChange={(date, dateString) => {
                  formik.setFieldValue("start_date", dateString);
                }}
              />
              {formik.errors.start_date && (
                <p className="text-[red]">{formik.errors.start_date}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>End Date</label>
              <DatePicker
                className="!h-[2.5rem] w-full"
                value={dayjs(formik.values.end_date)}
                allowClear={false}
                disabled={
                  formik.values.limit_type !== "BOTH" &&
                  formik.values.limit_type !== "DURATION"
                }
                onChange={(date, dateString) => {
                  formik.setFieldValue("end_date", dateString);
                }}
              />
              {formik.errors.end_date && (
                <p className="text-[red]">{formik.errors.end_date}</p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Starting Quota</label>
              <Input
                placeholder="Starting Quota"
                name="starting_quota"
                value={formik.values.starting_quota}
                className="!h-[2.5rem] w-full"
                disabled={
                  formik.values.limit_type !== "BOTH" &&
                  formik.values.limit_type !== "QUOTA"
                }
                onChange={formik.handleChange}
              />
              {formik.errors.starting_quota && (
                <p className="text-[red]">{formik.errors.starting_quota}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Offer Status</label>
              <Select
                options={statusDropdown}
                value={formik.values.offer_status}
                onSelect={(value) => {
                  formik.setFieldValue("offer_status", value);
                }}
                className="w-full"
                placeholder="Offer Status"
              />
              {formik.errors.offer_status && (
                <p className="text-[red]">{formik.errors.offer_status}</p>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {updateLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Update</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateOffer;
