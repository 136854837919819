import
{
    UPLOAD_OFFER_CODES_LOADING,
    UPLOAD_OFFER_CODES_SUCCESS,
    UPLOAD_OFFER_CODES_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const uploadOfferCodesAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPLOAD_OFFER_CODES_LOADING, payload: true});
        const response = await createNewItemService('/api/stlr/offerCode/upload', data);
        dispatch({type: UPLOAD_OFFER_CODES_SUCCESS, payload: response.data});
        callback && callback();
    } catch (err) {
        dispatch({type: UPLOAD_OFFER_CODES_FAILURE, payload: err});
    } finally {
        dispatch({type: UPLOAD_OFFER_CODES_LOADING, payload: false});
    }
}