import
{
    DELETE_CATEGORY_LOADING,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { deleteItemService } from "services/deleteItemService";

export const deleteCategoryAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: DELETE_CATEGORY_LOADING, payload: true});
        const response = await deleteItemService('/api/stlr/category/', id);
        dispatch({type: DELETE_CATEGORY_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: DELETE_CATEGORY_FAILURE, payload: err});
    } finally {
        dispatch({type: DELETE_CATEGORY_LOADING, payload: false});
    }
}