import
{
    LOGIN_LOADING,
    LOGIN_SUCCESS, 
    LOGIN_FAILURE,
    UPDATE_PROFILE
}
from "redux/types";

const initialState = {
    loading: false,
    user: null,
    token: null,
    errors: null
}

export const loginReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_LOADING:
            return {...state, loading: action.payload};
        case LOGIN_SUCCESS:
            return {...state, user: action.payload.data.user, token: action.payload.data.token};
        case UPDATE_PROFILE:
            return {...state, user: action.payload.data};
        case LOGIN_FAILURE:
            return {...state, errors: action.payload};
        default:
            return state;
    }
}