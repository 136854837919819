import
{
    LIST_MERCHANTS_LOADING,
    LIST_MERCHANTS_SUCCESS,
    LIST_MERCHANTS_FAILURE
}
from "redux/types";

const initialState = {
    merchantsLoading: false,
    merchants: null,
    errors: null
}

export const listMerchantsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_MERCHANTS_LOADING :
            return {...state, merchantsLoading: action.payload};
        case LIST_MERCHANTS_SUCCESS :
            return {...state, merchants: action.payload.data};
        case LIST_MERCHANTS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}