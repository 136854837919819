import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { listChannelMerchantsAction } from "redux/actions/channelMerchantsActions/listChannelMerchantsAction";
import { RootState } from "redux/rootReducer";
import { Modal, Spin, Table, Tag } from "antd";
import MerchantTableCols from "models/tableColumns/MerchantTableCols";
import { PlusOutlined } from "@ant-design/icons";
import { CREATE_CHANNEL_MERCHANT_PATH } from "Routes/paths";
import { deleteChannelMerchantAction } from "redux/actions/channelMerchantsActions/deleteChannelMerchantAction";
import { listChannelOffersAction } from "redux/actions/channelOffersActions/listChannelOffersAction";
import OfferTableCols from "models/tableColumns/OfferTableCols";
import { deleteOfferAction } from "redux/actions/offersActions/deleteOfferAction";
import { listOffersAction } from "redux/actions/offersActions/listOffersAction";

const ChannelMerchant = () => {
  const { channelMerchantsLoading, channelMerchants } = useSelector(
    (state: RootState) => state.listChannelMerchantReducer
  );
  const { channelLoading, channel } = useSelector(
    (state: RootState) => state.listSingleChannelReducer
  );
  const { deleteLoading } = useSelector(
    (state: RootState) => state.deleteChannelMerchantReducer
  );
  const { merchantLoading } = useSelector(
    (state: RootState) => state.listSingleMerchantReducer
  );
  const { channelOffersLoading, channelOffers } = useSelector(
    (state: RootState) => state.listChannelOffersReducer
  );

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [image, setImage] = useState("");

  const [modalOpen, setModalOpen] = useState(false);

  const [deletingId, setDeletingId] = useState("");

  const { id } = useParams();

  const uniqueClassifications: { [name: string]: boolean } = {};

  // Step 2: Iterate through subCategories and populate uniqueCategories
  channel?.subClassifications?.forEach((subClassification: any) => {
    const classificationName = subClassification.classification.name;
    uniqueClassifications[classificationName] = true;
  });

  // Step 3: Extract unique category names from uniqueCategories object
  const uniqueClassificationNames = Object.keys(uniqueClassifications);

  useEffect(() => {
    dispatch(listChannelMerchantsAction(channel?.id ?? ""));
    dispatch(listChannelOffersAction(channel?.id));
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center justify-center mt-10 mb-10">
      <div className="w-[80%] flex flex-col items-start justify-start">
        <Spin spinning={channelLoading}>
          <div className="flex flex-row gap-x-3 items-center">
            <h1 className="font-bold text-4xl pl-4">{channel?.name}</h1>
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/public/uploads/channelLogos/${channel?.logo}`}
              alt="logo"
              className="rounded-full w-[4rem] h-[4rem] object-cover"
            />
          </div>
        </Spin>
        <p className="font-bold text-xl ml-5">Classifications</p>
        <div className="flex flex-row items-center gap-x-2 ml-5 flex-wrap">
          {uniqueClassificationNames?.length === 0 ? (
            <p>No Items</p>
          ) : (
            uniqueClassificationNames?.map((item: any) => {
              return (
                <Tag color="blue" key={item}>
                  <p>{item}</p>
                </Tag>
              );
            })
          )}
        </div>
        <p className="font-bold text-xl ml-5 mt-2">Sub Classifications</p>
        <div className="flex flex-row items-center gap-x-2 ml-5 flex-wrap">
          {channel?.subClassifications?.length === 0 ? (
            <p>No Items</p>
          ) : (
            channel?.subClassifications?.map((item: any) => {
              return (
                <Tag color="blue" key={item}>
                  <p>{item?.name}</p>
                </Tag>
              );
            })
          )}
        </div>
        <div className="w-full flex items-center justify-between">
          <p className="text-black font-bold ml-5 mt-4 text-xl">Merchants</p>
          <button
            onClick={() => {
              navigate(CREATE_CHANNEL_MERCHANT_PATH);
            }}
            className="px-6 py-4 w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-[--primary]"
          >
            <PlusOutlined className="font-bold text-white" />
          </button>
        </div>
        <Table
          className="w-full m-4 shadow-md overflow-x-auto"
          dataSource={channelMerchants}
          loading={channelMerchantsLoading || deleteLoading || merchantLoading}
          columns={MerchantTableCols(
            imageModalOpen,
            setImageModalOpen,
            image,
            setImage,
            (value: any) => {
              setModalOpen(true);
              setDeletingId(value);
            },
            async (value: any) => {
              await dispatch(listChannelOffersAction(channel?.id));
              navigate(`/dashboard/merchants/update/${value}`);
            },
            false
          )}
        />
        <p className="text-black font-bold ml-5 mt-4 text-xl">Offers</p>
        <Table
          className="w-full m-4 shadow-md overflow-x-auto"
          dataSource={channelOffers}
          loading={channelOffersLoading}
          columns={OfferTableCols(
            (value: any) => {
              setModalOpen(true);
              setDeletingId(value);
            },
            async (value: any) => {
              await dispatch(listChannelOffersAction(value ?? ""));
              navigate(`/dashboard/offers/update/${value}`);
            },
            async (value: any) => {},
            false,
            (value: any) => {
              navigate(`/dashboard/offers/view/${value}`);
            },
            true
          )}
        />
        {/* <Modal
          title="Delete Confirmation"
          open={modalOpen}
          onOk={async () => {
            await dispatch(deleteOfferAction(deletingId));
            setModalOpen(false);
            dispatch(listOffersAction(channel.id));
          }}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{
            className: "bg-[--primary]",
          }}
          confirmLoading={deleteLoading}
        >
          <p>Are you sure you want to delete: {deletingId}</p>
        </Modal> */}
      </div>
    </div>
  );
};

export default ChannelMerchant;
