import
{
    DELETE_SUBCLASSIFICATION_LOADING,
    DELETE_SUBCLASSIFICATION_SUCCESS,
    DELETE_SUBCLASSIFICATION_FAILURE
}
from "redux/types";

const initialState = {
    deleteSubClassificationLoading: false,
    success: null,
    errors: null
}

export const deleteSubClassificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DELETE_SUBCLASSIFICATION_LOADING :
            return {...state, deleteSubClassificationLoading: action.payload};
        case DELETE_SUBCLASSIFICATION_SUCCESS :
            return {...state, success: action.payload.success};
        case DELETE_SUBCLASSIFICATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}