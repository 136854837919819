import
{
    CREATE_SUBCATEGORY_LOADING,
    CREATE_SUBCATEGORY_SUCCESS,
    CREATE_SUBCATEGORY_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const createSubCategoryAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: CREATE_SUBCATEGORY_LOADING, payload: true});
        const response = await createNewItemService('/api/stlr/subCategory', data);
        dispatch({type: CREATE_SUBCATEGORY_SUCCESS, payload: response.data});
        callback && callback();
    } catch (err) {
        dispatch({type: CREATE_SUBCATEGORY_FAILURE, payload: err});
    } finally {
        dispatch({type: CREATE_SUBCATEGORY_LOADING, payload: false});
    }
}