import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { CHANNELS_PATH, MERCHANTS_PATH, USERS_PATH } from "Routes/paths";
import { RootState } from "redux/rootReducer";

const Dashboard = () => {
    const navigate = useNavigate();

    const { user } = useSelector((state: RootState) => state.loginReducer);

    useEffect(() => {
        if (!user) {
            navigate("/login");
        }
    }, [user]);

    return (
        <>
            {user?.role === "ADMIN"  && <Navigate to={USERS_PATH} />}
            {user?.role === "MERCHANT_ADMIN" && <Navigate to={CHANNELS_PATH} />}
            {user?.role === "CHANNEL_ADMIN" && <Navigate to={MERCHANTS_PATH} />}
        </>
    );
}

export default Dashboard;