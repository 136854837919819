import
{
    LIST_CLASSIFICATIONS_LOADING,
    LIST_CLASSIFICATIONS_SUCCESS,
    LIST_CLASSIFICATIONS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listItemsService } from "services/listItemsService";

export const listClassificationsAction = () => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_CLASSIFICATIONS_LOADING, payload: true});
        const response = await listItemsService('/api/stlr/classification');
        dispatch({type: LIST_CLASSIFICATIONS_SUCCESS, payload: response.data});
    } catch (err) { 
        dispatch({type: LIST_CLASSIFICATIONS_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_CLASSIFICATIONS_LOADING, payload: false});
    }
}