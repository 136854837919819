import
{
    LIST_CHANNEL_OFFERS_LOADING,
    LIST_CHANNEL_OFFERS_SUCCESS,
    LIST_CHANNEL_OFFERS_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const listChannelOffersAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: LIST_CHANNEL_OFFERS_LOADING, payload: true});
        const response = await listSingleItemService('/api/stlr/channelOffer/showAllChannelsOffers/', id);
        dispatch({type: LIST_CHANNEL_OFFERS_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: LIST_CHANNEL_OFFERS_FAILURE, payload: err});
    } finally {
        dispatch({type: LIST_CHANNEL_OFFERS_LOADING, payload: false});
    }
}