import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const UserTableCols = (handleDelete: any, handleUpdate: any) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (value: any) => {
      return <p>{value?.split("T")[0]}</p>;
    },
  },
  {
    title: "Actions",
    dataIndex: "uuid",
    key: "uuid",
    render: (value: any) => {
      return (<div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
        <DeleteOutlined className="text-[red]" onClick={() => handleDelete(value)} />
        <EditOutlined className="text-blue-600" onClick={() => handleUpdate(value)} />
      </div>)
    }
  }
];

export default UserTableCols;
