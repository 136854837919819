import
{
    CREATE_CATEGORY_LOADING,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE
}
from "redux/types";

const initialState = {
    createLoading: false,
    newCategory: null,
    errors: null
}

export const createCatgoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_CATEGORY_LOADING :
            return {...state, createLoading: action.payload};
        case CREATE_CATEGORY_SUCCESS :
            return {...state, newCategory: action.payload.data};
        case CREATE_CATEGORY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}