import
{
    UPDATE_USER_LOADING,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_PROFILE
}
from "redux/types";

import { Dispatch } from "redux";

import { updateItemService } from "services/updateItemService";

export const updateUserAction = (id: string, data: any, callback?: any, profile?: boolean) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: UPDATE_USER_LOADING, payload: true});
        const response = await updateItemService('api/stlr/user/', id, data);
        dispatch({type: UPDATE_USER_SUCCESS, payload: response.data});
        callback && callback();
        if (profile) {
            dispatch({type: UPDATE_PROFILE, payload: response.data});
        }
    } catch (err) {
        dispatch({type: UPDATE_USER_FAILURE, payload: err});
    } finally {
        dispatch({type: UPDATE_USER_LOADING, payload: false});
    }
}