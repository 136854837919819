import
{
    DELETE_CHANNEL_LOADING,
    DELETE_CHANNEL_SUCCESS,
    DELETE_CHANNEL_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { deleteItemService } from "services/deleteItemService";

export const deleteChannelAction = (id: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: DELETE_CHANNEL_LOADING, payload: true});
        const response = await deleteItemService('/api/stlr/channel/', id);
        dispatch({type: DELETE_CHANNEL_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: DELETE_CHANNEL_FAILURE, payload: err});
    } finally {
        dispatch({type: DELETE_CHANNEL_LOADING, payload: false});
    }
}