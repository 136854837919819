import
{
    GET_SUBCLASSIFICATION_LOADING,
    GET_SUBCLASSIFICATION_SUCCESS,
    GET_SUBCLASSIFICATION_FAILURE
}
from "redux/types";

const initialState = {
    subClassificationLoading: false,
    subClassification: null,
    errors: null
}

export const listSingleSubClassificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_SUBCLASSIFICATION_LOADING :
            return {...state, subClassificationLoading: action.payload};
        case GET_SUBCLASSIFICATION_SUCCESS :
            return {...state, subClassification: action.payload.data};
        case GET_SUBCLASSIFICATION_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}