import { Input, Modal, Select, Spin } from "antd";
import Loader from "components/Loader/Loader";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as yup from "yup";
import CreateMerchantModel from "models/CreateMerchantModel";
import { RootState } from "redux/rootReducer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateMerchantAction } from "redux/actions/merchantsActions/updateMerchantAction";
import { useNavigate } from "react-router";
import { listSubCategoriesAction } from "redux/actions/subCategoriesActions/listSubCategoriesAction";
import { useDropdown } from "hooks/useDropdown";

const UpdateMerchant = () => {
  const { merchant } = useSelector(
    (state: RootState) => state.listSingleMerchantReducer
  );
  const { updateLoading } = useSelector(
    (state: RootState) => state.updateMerchantReducer
  );

  const { subCategoriesLoading, subCategories } = useSelector((state: RootState) => state.listSubCategoriesReducer);

  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: merchant?.name,
      logo: merchant?.logo,
      subCategories: merchant?.subCategories?.map((item: any) => {
        return {
          label: item.name,
          value: item.id
        }
      })
    },
    validationSchema: yup.object({
      name: yup.string().required("please enter this field"),
    }),
    onSubmit: async (values: CreateMerchantModel) => {
      const form: any = {
        name: values.name,
        subCategories: values.subCategories
      };
      if (typeof values.logo !== "string") {
        form.logo = values.logo;
      }
      await dispatch(
        updateMerchantAction(
          merchant?.uuid,
          form,
          () => navigate(-1)
        )
      );
    },
  });

  const { categoriesDropdown } = useDropdown();

  const [categoryId, setCategoryId] = useState("");

  const subCategoriesDropdown = subCategories?.map((item: any) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  useEffect(() => {
    dispatch(listSubCategoriesAction(categoryId));
  }, [categoryId]);

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Update Merchant</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Name</label>
              <Input
                placeholder="Name"
                name="name"
                value={formik.values.name}
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <p className="text-[red]">{formik.errors.name}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Logo</label>
              <Input
                placeholder="Email"
                type="file"
                name="email"
                className="!h-[2.5rem] w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files !== null) {
                    formik.setFieldValue("logo", e.target.files[0]);
                  }
                }}
              />
              <p
                onClick={() => setModalOpen(true)}
                className="ml-2 text-blue-600 hover:cursor-pointer hover:opacity-80"
              >
                preview
              </p>
              {formik.errors.logo && (
                <p className="text-[red]">please provide this field</p>
              )}
              <Modal
                title="Logo"
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                okButtonProps={{
                  style: {
                    display: "none",
                  },
                }}
                cancelButtonProps={{
                  style: {
                    display: "none",
                  },
                }}
              >
                <img
                  src={`https://apis.st-lr.com/public/uploads/merchantLogos/${formik.values.logo}`}
                  alt="channel-logo"
                  className="w-full h-full object-cover"
                />
              </Modal>
            </div>
          </div>

          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Category</label>
              <Select
                options={categoriesDropdown}
                onSelect={(value) => {
                  setCategoryId(value);
                }}
                className="w-full"
                placeholder="Category"
              />
              {formik.errors.name && (
                <p className="text-[red]">{formik.errors.name}</p>
              )}
            </div>
            <div className="select-spin-container w-[50%] flex flex-col items-start">
              <label>Sub Categories</label>
              <Spin spinning={subCategoriesLoading}>
                <Select
                  options={subCategoriesDropdown}
                  onChange={(value: any) => {
                    console.log("7asal ya basha");
                    formik.setFieldValue('subCategories', value);
                  }}
                  value={formik.values.subCategories}
                  mode="multiple"
                  allowClear
                  className="w-full"
                  placeholder="Category"
                />
                {formik.errors.subCategories && (
                  <p className="text-[red]">please provide this field</p>
                )}
              </Spin>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {updateLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Update</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateMerchant;
