import { Input, Modal, Select } from "antd";
import Loader from "components/Loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import UpdateUserModel from "models/UpdateUserModel";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { updateUserAction } from "redux/actions/userActions/updateUserAction";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useState } from "react";
import { useDropdown } from "hooks/useDropdown";

const Profile = () => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.loginReducer);

  const { updateLoading } = useSelector(
    (state: RootState) => state.updateUserReducer
  );

  const { rolesDropdown, channelsDropdown, merchantsDropdown } = useDropdown();

  const [modalOpen, setModalOpen] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");

  const [adminType, setAdminType] = useState("");

  const formik = useFormik({
    initialValues: {
      name: user?.name,
      email: user?.email,
      username: user?.username,
      role: user?.role,
      channel_id: user?.channel_id,
      merchant_id: user?.merchant_id,
    },
    validationSchema: yup.object({
      name: yup.string().min(2).required("please enter this field"),
      email: yup
        .string()
        .email("please provide a valid email")
        .required("please enter this field"),
      username: yup.string().required("please enter this field"),
      role: yup.string().required("please enter this field"),
      merchant_id: yup.string().when("role", ([role], sch) => {
        return role === "MERCHANT_ADMIN"
          ? sch.required("please enter this field")
          : sch.optional().nullable();
      }),
      channel_id: yup.string().when("role", ([role], sch) => {
        return role === "CHANNEL_ADMIN"
          ? sch.required("please enter this field")
          : sch.optional().nullable();
      }),
    }),
    onSubmit: async (values: UpdateUserModel) => {
      await dispatch(
        updateUserAction(
          user?.uuid,
          {
            name: values.name,
            email: values.email,
            username: values.username,
            role: values.role,
            channel_id: values.channel_id,
            merchant_id: values.merchant_id,
          },
          () => navigate(-1),
          true
        )
      );
    },
  });

  return (
    <div className="mt-10 w-full h-full flex flex-col items-center justify-start">
      <div className="w-[90%] bg-white shadow-md rounded-lg flex flex-col px-4 py-4 pb-10">
        <h1 className="font-bold text-lg">Update Profile</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="mt-8 w-full flex flex-col gap-y-6"
        >
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Name*</label>
              <Input
                placeholder="Name"
                name="name"
                value={formik.values.name}
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <p className="text-[red]">{formik.errors.name}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Email*</label>
              <Input
                placeholder="Email"
                name="email"
                value={formik.values.email}
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.email && (
                <p className="text-[red]">{formik.errors.email}</p>
              )}
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Role*</label>
              <Select
                options={rolesDropdown}
                disabled={true}
                onSelect={(value) => {
                  setAdminType(value);
                  formik.setFieldValue("role", value);
                }}
                value={formik.values.role}
                className="w-full"
                placeholder="Role"
              />
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Username*</label>
              <Input
                placeholder="Username"
                name="username"
                disabled={true}
                value={formik.values.username}
                className="!h-[2.5rem] w-full"
                onChange={formik.handleChange}
              />
              {formik.errors.username && (
                <p className="text-[red]">{formik.errors.username}</p>
              )}
            </div>
          </div>

          <div className="w-full flex flex-row items-center gap-x-10">
            <div className="w-[50%] flex flex-col items-start">
              <label>Channel</label>
              <Select
                placeholder="Channel"
                className="!h-[2.5rem] w-full"
                options={channelsDropdown}
                value={formik.values.channel_id}
                disabled={adminType !== "CHANNEL_ADMIN"}
                onSelect={(value: string) =>
                  formik.setFieldValue("channel_id", value)
                }
              />
              {formik.errors.channel_id && (
                <p className="text-[red]">{formik.errors.channel_id}</p>
              )}
            </div>
            <div className="w-[50%] flex flex-col items-start">
              <label>Merchant</label>
              <Select
                placeholder="Merchant"
                className="!h-[2.5rem] w-full"
                value={formik.values.merchant_id}
                options={merchantsDropdown}
                disabled={adminType !== "MERCHANT_ADMIN"}
                onSelect={(value: string) =>
                  formik.setFieldValue("merchant_id", value)
                }
              />
              {formik.errors.merchant_id && (
                <p className="text-[red]">{formik.errors.merchant_id}</p>
              )}
            </div>
          </div>

          <div className="w-full flex items-center justify-center">
            <button
              type="button"
              onClick={() => setModalOpen(true)}
              className="px-6 py-3 flex items-center justify-center bg-[--primary] rounded-md"
            >
              <p className="text-white">Update Password</p>
            </button>
          </div>

          <Modal
            title="Update Password"
            open={modalOpen}
            onOk={async () => {
              await dispatch(
                updateUserAction(
                  user?.uuid,
                  {
                    oldPassword: oldPassword,
                    password: password,
                  },
                  () => {}
                )
              );
              setModalOpen(false);
            }}
            onCancel={() => setModalOpen(false)}
            okButtonProps={{
              className: "bg-[--primary]",
            }}
            confirmLoading={updateLoading}
          >
            <div className="w-full flex flex-col items-start gap-y-3 px-4 py-4">
              <label>Old Password</label>
              <Input
                placeholder="old password"
                className="!h-[2.5rem] w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setOldPassword(e.target.value)
                }
              />
              <label>New Password</label>
              <Input
                placeholder="new password"
                className="!h-[2.5rem] w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
              />
            </div>
          </Modal>

          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="px-6 py-3 w-[8rem] flex items-center justify-center bg-[--primary] rounded-lg"
            >
              {updateLoading ? (
                <Loader />
              ) : (
                <p className="text-white font-bold">Update</p>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
