import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { CHANNEL_ADMIN_SIDE_BAR_ITEMS, MERCHANT_ADMIN_SIDE_BAR_ITEMS, STLR_ADMIN_SIDE_BAR_ITEMS } from "contstants/SideBarItems";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useResponsive } from "hooks/useResponsive";

const Sidebar = () => {
  const { user } = useSelector((state: RootState) => state.loginReducer);

  const [sideBarItems, setSideBarItems] = useState<any[]>();
  const [selectedItem, setSelectedItem] = useState("");

  const { isMax1110 } = useResponsive();

  const navigate = useNavigate();

  const highlightSidebarItem = () => {
    switch (window.location.pathname) {
      case "/dashboard/users":
        setSelectedItem("Users");
        return;
      case "/dashboard/merchants":
        setSelectedItem("Merchants");
        return;
      case "/dashboard/channels":
        setSelectedItem("Channels");
        return;
      case "/dashboard/offers":
        setSelectedItem("Offers");
        return;
      case "/dashboard/offer-codes":
        setSelectedItem("Offer Codes");
        return;
      case "/dashboard/vouchers":
        setSelectedItem("Vouchers");
        return;
      default:
        return;
    }
  };

  const getUserItems = () => {
    switch (user?.role) {
      case "ADMIN":
        return STLR_ADMIN_SIDE_BAR_ITEMS;
      case "MERCHANT_ADMIN":
        return MERCHANT_ADMIN_SIDE_BAR_ITEMS;
      case "CHANNEL_ADMIN":
        return CHANNEL_ADMIN_SIDE_BAR_ITEMS;
      default:
        return [];
    }
  };

  useEffect(() => {
    setSideBarItems(getUserItems());
  }, []);

  useEffect(() => {
    highlightSidebarItem();
  }, [window.location.pathname]);

  return !isMax1110 ? (
    <div className="h-full pt-10 flex flex-col items-center w-[280px] bg-[--primary] gap-y-5 border-r border-[#EAECF0]">
      {sideBarItems?.map((item: any, idx: number) => {
        return (
          <div
            key={idx}
            onClick={() => navigate(item.route)}
            className={`w-[85%] h-[44px] px-4 flex flex-row items-center justify-start gap-x-6 rounded-md transition-all ${
              selectedItem === item.name ? "bg-white translate-x-2" : ""
            } hover:cursor-pointer`}
          >
            <img
              src={selectedItem === item.name ? item.selectedIcon : item.icon}
              className="w-[1.5rem] h-[1.5rem]"
            />
            <p
              className={
                selectedItem === item.name
                  ? "text-black font-bold"
                  : "text-white"
              }
            >
              {item.name}
            </p>
          </div>
        );
      })}
    </div>
  ) : (
    <></>
  );
};

export default Sidebar;
