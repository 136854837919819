import
{
    UPDATE_CATEGORY_LOADING,
    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    updatedCategory: null,
    errors: null
}

export const updateCategoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_CATEGORY_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_CATEGORY_SUCCESS :
            return {...state, updatedCategory: action.payload.data};
        case UPDATE_CATEGORY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}