import
{
    LIST_CHANNEL_MERCHANTS_LOADING,
    LIST_CHANNEL_MERCHANTS_SUCCESS,
    LIST_CHANNEL_MERCHANTS_FAILURE,
    SET_CHANNEL_MERCHANT_ID
}
from "redux/types";

const initialState = {
    channelMerchantsLoading: false,
    channelMerchants: null,
    channelId: null,
    errors: null
}

export const listChannelMerchantReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_CHANNEL_MERCHANTS_LOADING :
            return {...state, channelMerchantsLoading: action.payload};
        case LIST_CHANNEL_MERCHANTS_SUCCESS :
            return {...state, channelMerchants: action.payload.data};
        case SET_CHANNEL_MERCHANT_ID :
            return {...state, channelId: action.payload};
        case LIST_CHANNEL_MERCHANTS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}