import api from "api";
import store from "redux/store";

export const deleteItemService = async (url: string, id: any) => {
    const response = await api.delete(
        id ? url + id : url,
        {
            headers: {
                "Authorization": `Bearer ${store.getState()?.loginReducer?.token}`
            }
        }
    );

    return response;
}