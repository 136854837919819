import { ChannelBlackIcon, ChannelWhiteIcon, MerchantBlackLogo, MerchantWhiteLogo, OfferBlackIcon, OfferCodeBlackIcon, OfferCodeWhiteIcon, OfferWhiteIcon, UserBlackIcon, UserWhiteIcon, VoucherBlackIcon, VoucherWhiteIcon } from "assets/icons";

export const STLR_ADMIN_SIDE_BAR_ITEMS = [
    {
        name: "Users",
        route: "/dashboard/users",
        icon: UserWhiteIcon,
        selectedIcon: UserBlackIcon
    },
    {
        name: "Merchants",
        route: "/dashboard/merchants",
        icon: MerchantWhiteLogo,
        selectedIcon: MerchantBlackLogo
    },
    {
        name: "Channels",
        route: "/dashboard/channels",
        icon: ChannelWhiteIcon,
        selectedIcon: ChannelBlackIcon
    },
    {
        name: "Offers",
        route: "/dashboard/offers",
        icon: OfferWhiteIcon,
        selectedIcon: OfferBlackIcon
    },
    {
        name: "Offer Codes",
        route: "/dashboard/offer-codes",
        icon: OfferCodeWhiteIcon,
        selectedIcon: OfferCodeBlackIcon
    },
    {
        name: "Vouchers",
        route: "/dashboard/vouchers",
        icon: VoucherWhiteIcon,
        selectedIcon: VoucherBlackIcon
    }
];

export const MERCHANT_ADMIN_SIDE_BAR_ITEMS = [
    {
        name: "Channels",
        route: "/dashboard/channels",
        icon: ChannelWhiteIcon,
        selectedIcon: ChannelBlackIcon
    },
    {
        name: "Offers",
        route: "/dashboard/offers",
        icon: OfferWhiteIcon,
        selectedIcon: OfferBlackIcon
    }
];

export const CHANNEL_ADMIN_SIDE_BAR_ITEMS = [
    {
        name: "Merchants",
        route: "/dashboard/merchants",
        icon: MerchantWhiteLogo,
        selectedIcon: MerchantBlackLogo
    },
    {
        name: "Offers",
        route: "/dashboard/offers",
        icon: OfferWhiteIcon,
        selectedIcon: OfferBlackIcon
    }
];
