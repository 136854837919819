import
{
    GET_CHANNEL_LOADING,
    GET_CHANNEL_SUCCESS,
    GET_CHANNEL_FAILURE
}
from "redux/types";

const initialState = {
    channelLoading: false,
    channel: null,
    errors: null
}

export const listSingleChannelReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_CHANNEL_LOADING :
            return {...state, channelLoading: action.payload};
        case GET_CHANNEL_SUCCESS :
            return {...state, channel: action.payload.data};
        case GET_CHANNEL_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}