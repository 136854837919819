import
{
    CREATE_VOUCHER_LOADING,
    CREATE_VOUCHER_SUCCESS,
    CREATE_VOUCHER_FAILURE
}
from "redux/types";

const initialState = {
    createLoading: false,
    success: null,
    errors: null
}

export const createVoucherReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_VOUCHER_LOADING :
            return {...state, createLoading: action.payload};
        case CREATE_VOUCHER_SUCCESS :
            return {...state, success: action.payload.data};
        case CREATE_VOUCHER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}