import { Empty, Modal, Spin, Table, Tag } from "antd";
import { useNavigate } from "react-router";
import ChannelTableCols from "models/tableColumns/ChannelTableCols";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/rootReducer";
import { useDispatch } from "react-redux";
import { listMerchantChannelsAction } from "redux/actions/merchantChannelsActions/listMerchantChannelsAction";
import { listSingleChannelAction } from "redux/actions/channelsActions/listSingleChannelAction";
import OfferTableCols from "models/tableColumns/OfferTableCols";
import { listSingleOfferAction } from "redux/actions/offersActions/listSingleOfferAction";
import { useResponsive } from "hooks/useResponsive";
import { listOffersAction } from "redux/actions/offersActions/listOffersAction";
import { PlusOutlined } from "@ant-design/icons";
import { CREATE_CHANNEL_OFFER_PATH, CREATE_OFFER_PATH } from "Routes/paths";
import { SET_MERCHANT_ID } from "redux/types";
import { deleteOfferAction } from "redux/actions/offersActions/deleteOfferAction";

const MerchantChannel = () => {
  const navigate = useNavigate();

  const dispatch: any = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [image, setImage] = useState("");

  const [deletingId, setDeletingId] = useState("");

  const { isMobile } = useResponsive();

  const { user } = useSelector((state: RootState) => state.loginReducer);

  const { merchantLoading, merchant } = useSelector(
    (state: RootState) => state.listSingleMerchantReducer
  );
  const { merchantChannelsLoading, merchantChannels } = useSelector(
    (state: RootState) => state.listMerchantChannelsReducer
  );

  const { offersLoading, offers } = useSelector(
    (state: RootState) => state.listOffersReducer
  );
  const { offerLoading } = useSelector(
    (state: RootState) => state.listSingleOfferReducer
  );
  const { deleteLoading } = useSelector(
    (state: RootState) => state.deleteOfferReducer
  );

  const uniqueCategories: { [name: string]: boolean } = {};

  // Step 2: Iterate through subCategories and populate uniqueCategories
  merchant?.subCategories?.forEach((subCategory: any) => {
    const categoryName = subCategory.category.name;
    uniqueCategories[categoryName] = true;
  });

  // Step 3: Extract unique category names from uniqueCategories object
  const uniqueCategoryNames = Object.keys(uniqueCategories);

  useEffect(() => {
    dispatch(listMerchantChannelsAction(merchant?.id));
    dispatch(listOffersAction(merchant?.id));
  }, []);

  return (
    <div className="w-full h-full flex flex-col items-center mb-10 mt-10 justify-start">
      <div
        className={`${
          isMobile ? "w-[90%]" : "w-[80%]"
        } h-full flex flex-col items-start justify-start`}
      >
        <Spin spinning={merchantLoading}>
          <div className="flex flex-row gap-x-3 items-center">
            <h1 className="font-bold text-4xl pl-4">{merchant?.name}</h1>
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/public/uploads/merchantLogos/${merchant?.logo}`}
              alt="logo"
              className="rounded-full w-[4rem] h-[4rem] object-cover"
            />
          </div>
        </Spin>
        <p className="font-bold text-xl ml-5">Categories</p>
        <div className="flex flex-row items-center gap-x-2 ml-5 flex-wrap">
          {uniqueCategoryNames?.length === 0 ? (
            <p>No Items</p>
          ) : (
            uniqueCategoryNames?.map((item: any) => {
              return (
                <Tag color="blue" key={item}>
                  <p>{item}</p>
                </Tag>
              );
            })
          )}
        </div>
        <p className="font-bold text-xl ml-5 mt-2">Sub Categories</p>
        <div className="flex flex-row items-center gap-x-2 ml-5 flex-wrap">
          {merchant?.subCategories?.length === 0 ? (
            <p>No Items</p>
          ) : (
            merchant?.subCategories?.map((item: any) => {
              return (
                <Tag color="blue" key={item}>
                  <p>{item?.name}</p>
                </Tag>
              );
            })
          )}
        </div>
        <p className="text-black font-bold text-xl ml-5 mt-4">Channels</p>

        <Table
          className="w-full min-h-[11rem] m-4 shadow-md overflow-x-auto"
          dataSource={merchantChannels}
          loading={merchantChannelsLoading || merchantLoading}
          columns={ChannelTableCols(
            imageModalOpen,
            setImageModalOpen,
            image,
            setImage,
            user?.role === "ADMIN",
            (value: any) => {
              setModalOpen(true);
              setDeletingId(value);
            },
            async (value: any) => {
              await dispatch(listSingleChannelAction(value ?? ""));
              navigate(`/dashboard/channels/update/${value}`);
            },
            false,
            false
          )}
        />
        <div className="w-full flex items-center justify-between">
          <p className="text-black font-bold text-xl ml-5 mt-4">Offers</p>
          <button
            onClick={() => {
              dispatch({ type: SET_MERCHANT_ID, payload: merchant?.id });
              navigate(CREATE_OFFER_PATH);
            }}
            className="px-6 py-4 w-[2.5rem] h-[2.5rem] rounded-lg flex items-center justify-center bg-[--primary]"
          >
            <PlusOutlined className="font-bold text-white" />
          </button>
        </div>
        <Table
          className="w-full m-4 min-h-[12.5rem] shadow-md overflow-x-auto"
          dataSource={offers}
          loading={offersLoading || offerLoading}
          columns={OfferTableCols(
            (value: any) => {
              setModalOpen(true);
              setDeletingId(value);
            },
            async (value: any) => {
              await dispatch(listSingleOfferAction(value ?? ""));
              navigate(`/dashboard/offers/update/${value}`);
            },
            async (value: any) => {
              await dispatch(listSingleOfferAction(value ?? ""));
              navigate(CREATE_CHANNEL_OFFER_PATH);
            },
            true,
            (value: any) => {
              navigate(`/dashboard/offers/view/${value}`);
            },
            true
          )}
        />

        <Modal
          title="Delete Confirmation"
          open={modalOpen}
          onOk={async () => {
            await dispatch(deleteOfferAction(deletingId));
            setModalOpen(false);
            dispatch(dispatch(listOffersAction(merchant?.id)));
          }}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{
            className: "bg-[--primary]",
          }}
            confirmLoading={deleteLoading}
        >
          <p>Are you sure you want to delete: {deletingId}</p>
        </Modal>
      </div>
    </div>
  );
};

export default MerchantChannel;
