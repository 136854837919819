import
{
    CREATE_CLASSIFICATION_LOADING,
    CREATE_CLASSIFICATION_SUCCESS,
    CREATE_CLASSIFICATION_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { createNewItemService } from "services/createNewItemService";

export const createClassificationAction = (data: any, callback?: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({type: CREATE_CLASSIFICATION_LOADING, payload: true});
        const response = await createNewItemService('/api/stlr/classification', data);
        dispatch({type: CREATE_CLASSIFICATION_SUCCESS, payload: response.data});
        callback && callback();
    } catch (err) { 
        dispatch({type: CREATE_CLASSIFICATION_FAILURE, payload: err});
    } finally {
        dispatch({type: CREATE_CLASSIFICATION_LOADING, payload: false});
    }
}