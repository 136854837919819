import
{
    LIST_CHANNEL_OFFERS_LOADING,
    LIST_CHANNEL_OFFERS_SUCCESS,
    LIST_CHANNEL_OFFERS_FAILURE
}
from "redux/types";

const initialState = {
    channelOffersLoading: false,
    channelOffers: null,
    errors: null
};

export const listChannelOffersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_CHANNEL_OFFERS_LOADING :
            return {...state, channelOffersLoading: action.payload};
        case LIST_CHANNEL_OFFERS_SUCCESS :
            return {...state, channelOffers: action.payload.data};
        case LIST_CHANNEL_OFFERS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}