import
{
    CREATE_CHANNEL_OFFER_LOADING,
    CREATE_CHANNEL_OFFER_SUCCESS,
    CREATE_CHANNEL_OFFER_FAILURE
}
from "redux/types";

const initialState = {
    createLoading: false,
    newChannelOffer: null,
    errors: null
}

export const createChannelOfferReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_CHANNEL_OFFER_LOADING :
            return {...state, createLoading: action.payload};
        case CREATE_CHANNEL_OFFER_SUCCESS :
            return {...state, newChannelOffer: action.payload.data};
        case CREATE_CHANNEL_OFFER_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}