import
{
    UPDATE_SUBCATEGORY_LOADING,
    UPDATE_SUBCATEGORY_SUCCESS,
    UPDATE_SUBCATEGORY_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    updatedSubCategory: null,
    errors: null
}

export const updateSubCategoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_SUBCATEGORY_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_SUBCATEGORY_SUCCESS :
            return {...state, updatedSubCategory: action.payload.data};
        case UPDATE_SUBCATEGORY_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}