import
{
    LIST_CLASSIFICATIONS_LOADING,
    LIST_CLASSIFICATIONS_SUCCESS,
    LIST_CLASSIFICATIONS_FAILURE
}
from "redux/types";

const initialState = {
    classificationsLoading: false,
    classifications: null,
    errors: null
}

export const listClassificationsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_CLASSIFICATIONS_LOADING :
            return {...state, classificationsLoading: action.payload};
        case LIST_CLASSIFICATIONS_SUCCESS :
            return {...state, classifications: action.payload.data};
        case LIST_CLASSIFICATIONS_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}