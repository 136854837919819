import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Popover } from "antd";

const OfferTableCols = (
  handleDelete: any,
  handleUpdate: any,
  handleLinkChannel: any,
  showLinkChannel: boolean,
  handleShowOffer: any,
  showActions: boolean
) => [
  {
    title: "Merchant",
    dataIndex: "merchant",
    key: "merchant",
    render: (value: any) => {
      return <p>{value.name}</p>;
    },
  },
  {
    title: "Discount Type",
    dataIndex: "discount_type",
    key: "discount_type",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Discount",
    dataIndex: "discount",
    key: "discount",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Min Amount",
    dataIndex: "min_amount",
    key: "min_amount",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Limit Type",
    dataIndex: "limit_type",
    key: "limit_type",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Max Amount",
    dataIndex: "max_amount",
    key: "max_amount",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
    render: (value: any) => {
      return <p>{value?.split(" ")[0]}</p>;
    },
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
    render: (value: any) => {
      return <p>{value?.split(" ")[0]}</p>;
    },
  },
  {
    title: "Starting Quota",
    dataIndex: "starting_quota",
    key: "starting_quota",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Remaining Quota",
    dataIndex: "remaining_quota",
    key: "remaining_quota",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Offer Status",
    dataIndex: "offer_status",
    key: "offer_status",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  showActions
    ? {
        title: "Actions",
        dataIndex: "id",
        key: "id",
        render: (value: any) => {
          return (
            <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
              <DeleteOutlined
                className="text-[red]"
                onClick={() => handleDelete(value)}
              />
              <EditOutlined
                className="text-blue-600"
                onClick={() => handleUpdate(value)}
              />
              <a
                onClick={() => handleShowOffer(value)}
                className="text-blue-600 hover:opacity-80"
              >
                view
              </a>
              {showLinkChannel && (
                <Popover content={<p>Link a Channel</p>}>
                  <PlusCircleOutlined
                    className="text-green-400"
                    onClick={() => handleLinkChannel(value)}
                  />
                </Popover>
              )}
            </div>
          );
        },
      }
    : {},
];

export default OfferTableCols;
