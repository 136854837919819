import
{
    GET_OFFER_CODE_LOADING,
    GET_OFFER_CODE_SUCCESS,
    GET_OFFER_CODE_FAILURE
}
from "redux/types";

import { Dispatch } from "redux";

import { listSingleItemService } from "services/listSingleItemService";

export const getOfferCodeAction = (id: string) => async (dispatch: any) => {
    try {
        dispatch({type: GET_OFFER_CODE_LOADING, payload: true});
        const response = await listSingleItemService(`/api/stlr/offerCode/`, id);
        dispatch({type: GET_OFFER_CODE_SUCCESS, payload: response.data});
    } catch (err) {
        dispatch({type: GET_OFFER_CODE_FAILURE, payload: err});
    } finally {
        dispatch({type: GET_OFFER_CODE_LOADING, payload: false});
    }
}