import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Modal, Popover, Tag } from "antd";

const ChannelTableCols = (
  imageModalOpen: boolean,
  setImageModalOpen: any,
  image: any,
  setImage: any,
  showActions: boolean,
  handleDelete: any,
  handleUpdate: any,
  showLinkMerchant: boolean,
  showView: boolean,
  handleLinkMerchant?: any,
  handleView?: any,
  showSubClassifications?: boolean
) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (value: any) => {
      return <p>{value}</p>;
    },
  },
  {
    title: "Logo",
    dataIndex: "logo",
    key: "logo",
    render: (value: any, id: any) => {
      return (
        <>
          <p
            onClick={() => {
              setImageModalOpen(true);
              setImage(
                `https://apis.st-lr.com/public/uploads/channelLogos/${value}`
              );
            }}
            className="text-blue-600 hover:cursor-pointer"
          >
            logo
          </p>

          <Modal
            key={id}
            title="Logo"
            open={imageModalOpen}
            onCancel={() => setImageModalOpen(false)}
            okButtonProps={{
              style: {
                display: "none",
              },
            }}
            cancelButtonProps={{
              style: {
                display: "none",
              },
            }}
          >
            <img
              src={image}
              alt="channel-logo"
              className="w-full h-full object-cover"
            />
          </Modal>
        </>
      );
    },
  },
  showSubClassifications
    ? {
        title: "Sub Categories",
        dataIndex: "subClassifications",
        key: "subClassifications",
        render: (value: any) => {
          return (
            <div className="flex items-center gap-x-1">
              {value?.map((item: any) => {
                return <Tag color="blue">{item?.name}</Tag>;
              })}
            </div>
          );
        },
      }
    : {},
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (value: any) => {
      return <p>{value?.split("T")[0]}</p>;
    },
  },
  showActions
    ? {
        title: "Actions",
        dataIndex: "uuid",
        key: "uuid",
        render: (value: any) => {
          return (
            <div className="flex flex-row items-center gap-x-4 hover:cursor-pointer">
              <DeleteOutlined
                className="text-[red]"
                onClick={() => handleDelete(value)}
              />
              <EditOutlined
                className="text-blue-600"
                onClick={() => handleUpdate(value)}
              />

              {showLinkMerchant && (
                <Popover content={<p>Link a Merchant</p>}>
                  <PlusCircleOutlined
                    className="text-green-400"
                    onClick={() => handleLinkMerchant(value)}
                  />
                </Popover>
              )}
              {showView && (
                <a
                  onClick={() => handleView(value)}
                  // href={`/dashboard/channel-merchant/${value}`}
                  className="text-blue-600 hover:opacity-80"
                >
                  view
                </a>
              )}
            </div>
          );
        },
      }
    : {},
];

export default ChannelTableCols;
