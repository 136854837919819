// Auth Types
export const LOG_OUT = "LOG_OUT";

export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

// Create User Types
export const CREATE_USER_LOADING = "CREATE_USER_LOADING";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

// List Users Types
export const LIST_USERS_LOADING = "LIST_USERS_LOADING";
export const LIST_USERS_SUCCESS = "LIST_USERS_SUCCESS";
export const LIST_USERS_FAILURE = "LIST_USERS_FAILURE";

// List Users Types
export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

// Get User Types
export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

// Update User Types
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

// List Merchants Types
export const LIST_MERCHANTS_LOADING = "LIST_MERCHANTS_LOADING";
export const LIST_MERCHANTS_SUCCESS = "LIST_MERCHANTS_SUCCESS";
export const LIST_MERCHANTS_FAILURE = "LIST_MERCHANTS_FAILURE";

// Create Merchant Types
export const CREATE_MERCHANT_LOADING = "CREATE_MERCHANT_LOADING";
export const CREATE_MERCHANT_SUCCESS = "CREATE_MERCHANT_SUCCESS";
export const CREATE_MERCHANT_FAILURE = "CREATE_MERCHANT_FAILURE";

// Get Merchant Types
export const GET_MERCHANT_LOADING = "GET_MERCHANT_LOADING";
export const GET_MERCHANT_SUCCESS = "GET_MERCHANT_SUCCESS";
export const GET_MERCHANT_FAILURE = "GET_MERCHANT_FAILURE";

// Update Merchant Types
export const UPDATE_MERCHANT_LOADING = "UPDATE_MERCHANT_LOADING";
export const UPDATE_MERCHANT_SUCCESS = "UPDATE_MERCHANT_SUCCESS";
export const UPDATE_MERCHANT_FAILURE = "UPDATE_MERCHANT_FAILURE";

// Delete Merchants Types
export const DELETE_MERCHANT_LOADING = "DELETE_MERCHANT_LOADING";
export const DELETE_MERCHANT_SUCCESS = "DELETE_MERCHANT_SUCCESS";
export const DELETE_MERCHANT_FAILURE = "DELETE_MERCHANT_FAILURE";

// List Channels Types
export const LIST_CHANNELS_LOADING = "LIST_CHANNELS_LOADING";
export const LIST_CHANNELS_SUCCESS = "LIST_CHANNELS_SUCCESS";
export const LIST_CHANNELS_FAILURE = "LIST_CHANNELS_FAILURE";

// Create Channel Types
export const CREATE_CHANNEL_LOADING = "CREATE_CHANNEL_LOADING";
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS";
export const CREATE_CHANNEL_FAILURE = "CREATE_CHANNEL_FAILURE";

// Delete Channel Types
export const DELETE_CHANNEL_LOADING = "DELETE_CHANNEL_LOADING";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_FAILURE = "DELETE_CHANNEL_FAILURE";

// Get Channel Types
export const GET_CHANNEL_LOADING = "GET_CHANNEL_LOADING";
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS";
export const GET_CHANNEL_FAILURE = "GET_CHANNEL_FAILURE";

// Update Channel Types
export const UPDATE_CHANNEL_LOADING = "UPDATE_CHANNEL_LOADING";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";
export const UPDATE_CHANNEL_FAILURE = "UPDATE_CHANNEL_FAILURE";

// List Categories Types
export const LIST_CATEGORIES_LOADING = "LIST_CATEGORIES_LOADING";
export const LIST_CATEGORIES_SUCCESS = "LIST_CATEGORIES_SUCCESS";
export const LIST_CATEGORIES_FAILURE = "LIST_CATEGORIES_FAILURE";

// Create Category Types
export const CREATE_CATEGORY_LOADING = "CREATE_CATEGORY_LOADING";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";

// Get Category Types
export const GET_CATEGORY_LOADING = "GET_CATEGORY_LOADING";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILURE = "GET_CATEGORY_FAILURE";

// Update Category Types
export const UPDATE_CATEGORY_LOADING = "UPDATE_CATEGORY_LOADING";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

// Delete Category Types
export const DELETE_CATEGORY_LOADING = "DELETE_CATEGORY_LOADING";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

// List SubCategories Types
export const LIST_SUBCATEGORIES_LOADING = "LIST_SUBCATEGORIES_LOADING";
export const LIST_SUBCATEGORIES_SUCCESS = "LIST_SUBCATEGORIES_SUCCESS";
export const LIST_SUBCATEGORIES_FAILURE = "LIST_SUBCATEGORIES_FAILURE";

// Create SubCategory Types
export const CREATE_SUBCATEGORY_LOADING = "CREATE_SUBCATEGORY_LOADING";
export const CREATE_SUBCATEGORY_SUCCESS = "CREATE_SUBCATEGORY_SUCCESS";
export const CREATE_SUBCATEGORY_FAILURE = "CREATE_SUBCATEGORY_FAILURE";

// Get SubCategory Types
export const GET_SUBCATEGORY_LOADING = "GET_SUBCATEGORY_LOADING";
export const GET_SUBCATEGORY_SUCCESS = "GET_SUBCATEGORY_SUCCESS";
export const GET_SUBCATEGORY_FAILURE = "GET_SUBCATEGORY_FAILURE";

// Update SubCategory Types
export const UPDATE_SUBCATEGORY_LOADING = "UPDATE_SUBCATEGORY_LOADING";
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS";
export const UPDATE_SUBCATEGORY_FAILURE = "UPDATE_SUBCATEGORY_FAILURE";

// Delete SubCategory Types
export const DELETE_SUBCATEGORY_LOADING = "DELETE_SUBCATEGORY_LOADING";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAILURE = "DELETE_SUBCATEGORY_FAILURE";

// List Classifications Types
export const LIST_CLASSIFICATIONS_LOADING = "LIST_CLASSIFICATIONS_LOADING";
export const LIST_CLASSIFICATIONS_SUCCESS = "LIST_CLASSIFICATIONS_SUCCESS";
export const LIST_CLASSIFICATIONS_FAILURE = "LIST_CLASSIFICATIONS_FAILURE";

// Create Classification Types
export const CREATE_CLASSIFICATION_LOADING = "CREATE_CLASSIFICATION_LOADING";
export const CREATE_CLASSIFICATION_SUCCESS = "CREATE_CLASSIFICATION_SUCCESS";
export const CREATE_CLASSIFICATION_FAILURE = "CREATE_CLASSIFICATION_FAILURE";

// Get Classification Types
export const GET_CLASSIFICATION_LOADING = "GET_CLASSIFICATION_LOADING";
export const GET_CLASSIFICATION_SUCCESS = "GET_CLASSIFICATION_SUCCESS";
export const GET_CLASSIFICATION_FAILURE = "GET_CLASSIFICATION_FAILURE";

// Update Classification Types
export const UPDATE_CLASSIFICATION_LOADING = "UPDATE_CLASSIFICATION_LOADING";
export const UPDATE_CLASSIFICATION_SUCCESS = "UPDATE_CLASSIFICATION_SUCCESS";
export const UPDATE_CLASSIFICATION_FAILURE = "UPDATE_CLASSIFICATION_FAILURE";

// Delete Classification Types
export const DELETE_CLASSIFICATION_LOADING = "DELETE_CLASSIFICATION_LOADING";
export const DELETE_CLASSIFICATION_SUCCESS = "DELETE_CLASSIFICATION_SUCCESS";
export const DELETE_CLASSIFICATION_FAILURE = "DELETE_CLASSIFICATION_FAILURE";

// List SubClassifications Types
export const LIST_SUBCLASSIFICATIONS_LOADING = "LIST_SUBCLASSIFICATIONS_LOADING";
export const LIST_SUBCLASSIFICATIONS_SUCCESS = "LIST_SUBCLASSIFICATIONS_SUCCESS";
export const LIST_SUBCLASSIFICATIONS_FAILURE = "LIST_SUBCLASSIFICATIONS_FAILURE";

// Create SubClassification Types
export const CREATE_SUBCLASSIFICATION_LOADING = "CREATE_SUBCLASSIFICATION_LOADING";
export const CREATE_SUBCLASSIFICATION_SUCCESS = "CREATE_SUBCLASSIFICATION_SUCCESS";
export const CREATE_SUBCLASSIFICATION_FAILURE = "CREATE_SUBCLASSIFICATION_FAILURE";

// Get SubClassification Types
export const GET_SUBCLASSIFICATION_LOADING = "GET_SUBCLASSIFICATION_LOADING";
export const GET_SUBCLASSIFICATION_SUCCESS = "GET_SUBCLASSIFICATION_SUCCESS";
export const GET_SUBCLASSIFICATION_FAILURE = "GET_SUBCLASSIFICATION_FAILURE";

// Update SubClassification Types
export const UPDATE_SUBCLASSIFICATION_LOADING = "UPDATE_SUBCLASSIFICATION_LOADING";
export const UPDATE_SUBCLASSIFICATION_SUCCESS = "UPDATE_SUBCLASSIFICATION_SUCCESS";
export const UPDATE_SUBCLASSIFICATION_FAILURE = "UPDATE_SUBCLASSIFICATION_FAILURE";

// Delete SubClassification Types
export const DELETE_SUBCLASSIFICATION_LOADING = "DELETE_SUBCLASSIFICATION_LOADING";
export const DELETE_SUBCLASSIFICATION_SUCCESS = "DELETE_SUBCLASSIFICATION_SUCCESS";
export const DELETE_SUBCLASSIFICATION_FAILURE = "DELETE_SUBCLASSIFICATION_FAILURE";

// List ChannelMerchants Types
export const LIST_CHANNEL_MERCHANTS_LOADING = "LIST_CHANNEL_MERCHANTS_LOADING";
export const LIST_CHANNEL_MERCHANTS_SUCCESS = "LIST_CHANNEL_MERCHANTS_SUCCESS";
export const LIST_CHANNEL_MERCHANTS_FAILURE = "LIST_CHANNEL_MERCHANTS_FAILURE";
export const SET_CHANNEL_MERCHANT_ID = "SET_CHANNEL_ID";

// Create ChannelMerchant Types
export const CREATE_CHANNEL_MERCHANT_LOADING = "CREATE_CHANNEL_MERCHANT_LOADING";
export const CREATE_CHANNEL_MERCHANT_SUCCESS = "CREATE_CHANNEL_MERCHANT_SUCCESS";
export const CREATE_CHANNEL_MERCHANT_FAILURE = "CREATE_CHANNEL_MERCHANT_FAILURE";
export const SET_CHANNEL_ID = "SET_CHANNEL_ID";

// Delete ChannelMerchant Types
export const DELETE_CHANNEL_MERCHANT_LOADING = "DELETE_CHANNEL_MERCHANT_LOADING";
export const DELETE_CHANNEL_MERCHANT_SUCCESS = "DELETE_CHANNEL_MERCHANT_SUCCESS";
export const DELETE_CHANNEL_MERCHANT_FAILURE = "DELETE_CHANNEL_MERCHANT_FAILURE";

// List MerchantChannels Types
export const LIST_MERCHANT_CHANNELS_LOADING = "LIST_MERCHANT_CHANNELS_LOADING";
export const LIST_MERCHANT_CHANNELS_SUCCESS = "LIST_MERCHANT_CHANNELS_SUCCESS";
export const LIST_MERCHANT_CHANNELS_FAILURE = "LIST_MERCHANT_CHANNELS_FAILURE";

// List Offers Types
export const LIST_OFFERS_LOADING = "LIST_OFFERS_LOADING";
export const LIST_OFFERS_SUCCESS = "LIST_OFFERS_SUCCESS";
export const LIST_OFFERS_FAILURE = "LIST_OFFERS_FAILURE";

// Create Offer Types
export const CREATE_OFFER_LOADING = "CREATE_OFFER_LOADING";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_FAILURE = "CREATE_OFFER_FAILURE";
export const SET_MERCHANT_ID = "SET_MERCHANT_ID";

// Get Offer Types
export const GET_OFFER_LOADING = "GET_OFFER_LOADING";
export const GET_OFFER_SUCCESS = "GET_OFFER_SUCCESS";
export const GET_OFFER_FAILURE = "GET_OFFER_FAILURE";

// Update Offer Types
export const UPDATE_OFFER_LOADING = "UPDATE_OFFER_LOADING";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_SUCCESS";
export const UPDATE_OFFER_FAILURE = "UPDATE_OFFER_FAILURE";

// Delete Offer Types
export const DELETE_OFFER_LOADING = "DELETE_OFFER_LOADING";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILURE = "DELETE_OFFER_FAILURE";

// List Channel Offers Types
export const LIST_CHANNEL_OFFERS_LOADING = "LIST_CHANNEL_OFFERS_LOADING";
export const LIST_CHANNEL_OFFERS_SUCCESS = "LIST_CHANNEL_OFFERS_SUCCESS";
export const LIST_CHANNEL_OFFERS_FAILURE = "LIST_CHANNEL_OFFERS_FAILURE";

// Create Channel Offer Types
export const CREATE_CHANNEL_OFFER_LOADING = "CREATE_CHANNEL_OFFER_LOADING";
export const CREATE_CHANNEL_OFFER_SUCCESS = "CREATE_CHANNEL_OFFER_SUCCESS";
export const CREATE_CHANNEL_OFFER_FAILURE = "CREATE_CHANNEL_OFFER_FAILURE";

// List Offer Codes Types
export const LIST_OFFER_CODES_LOADING = "LIST_OFFER_CODES_LOADING";
export const LIST_OFFER_CODES_SUCCESS = "LIST_OFFER_CODES_SUCCESS";
export const LIST_OFFER_CODES_FAILURE = "LIST_OFFER_CODES_FAILURE";

// Upload Offer Codes Types
export const UPLOAD_OFFER_CODES_LOADING = "UPLOAD_OFFER_CODES_LOADING";
export const UPLOAD_OFFER_CODES_SUCCESS = "UPLOAD_OFFER_CODES_SUCCESS";
export const UPLOAD_OFFER_CODES_FAILURE = "UPLOAD_OFFER_CODES_FAILURE";

// Generate Offer Codes Types
export const GENERATE_OFFER_CODES_LOADING = "GENERATE_OFFER_CODES_LOADING";
export const GENERATE_OFFER_CODES_SUCCESS = "GENERATE_OFFER_CODES_SUCCESS";
export const GENERATE_OFFER_CODES_FAILURE = "GENERATE_OFFER_CODES_FAILURE";

// Get Offer Code Types
export const GET_OFFER_CODE_LOADING = "GET_OFFER_CODE_LOADING";
export const GET_OFFER_CODE_SUCCESS = "GET_OFFER_CODE_SUCCESS";
export const GET_OFFER_CODE_FAILURE = "GET_OFFER_CODE_FAILURE";

// Update Offer Code Types
export const UPDATE_OFFER_CODE_LOADING = "UPDATE_OFFER_CODE_LOADING";
export const UPDATE_OFFER_CODE_SUCCESS = "UPDATE_OFFER_CODE_SUCCESS";
export const UPDATE_OFFER_CODE_FAILURE = "UPDATE_OFFER_CODE_FAILURE";

// List Vouchers Types
export const LIST_VOUCHERS_LOADING = "LIST_VOUCHERS_LOADING";
export const LIST_VOUCHERS_SUCCESS = "LIST_VOUCHERS_SUCCESS";
export const LIST_VOUCHERS_FAILURE = "LIST_VOUCHERS_FAILURE";

// Create Vouchers Types
export const CREATE_VOUCHER_LOADING = "CREATE_VOUCHER_LOADING";
export const CREATE_VOUCHER_SUCCESS = "CREATE_VOUCHER_SUCCESS";
export const CREATE_VOUCHER_FAILURE = "CREATE_VOUCHER_FAILURE";