import
{
    LIST_SUBCATEGORIES_LOADING,
    LIST_SUBCATEGORIES_SUCCESS,
    LIST_SUBCATEGORIES_FAILURE
}
from "redux/types";

const initialState = {
    subCategoriesLoading: false,
    subCategories: null,
    errors: null
}

export const listSubCategoriesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LIST_SUBCATEGORIES_LOADING :
            return {...state, subCategoriesLoading: action.payload};
        case LIST_SUBCATEGORIES_SUCCESS :
            return {...state, subCategories: action.payload.data};
        case LIST_SUBCATEGORIES_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state; 
    }
}