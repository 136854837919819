import
{
    UPDATE_MERCHANT_LOADING,
    UPDATE_MERCHANT_SUCCESS,
    UPDATE_MERCHANT_FAILURE
}
from "redux/types";

const initialState = {
    updateLoading: false,
    updatedMerchant: null,
    errors: null
}

export const updateMerchantReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_MERCHANT_LOADING :
            return {...state, updateLoading: action.payload};
        case UPDATE_MERCHANT_SUCCESS :
            return {...state, updatedMerchant: action.payload.data};
        case UPDATE_MERCHANT_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}