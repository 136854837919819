import
{
    CREATE_CHANNEL_MERCHANT_LOADING,
    CREATE_CHANNEL_MERCHANT_SUCCESS,
    CREATE_CHANNEL_MERCHANT_FAILURE,
    SET_CHANNEL_ID
}
from "redux/types";

const initialState = {
    createLoading: false,
    newChannelMerchant: null,
    channelId: null,
    errors: null
}

export const createChannelMerchantReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_CHANNEL_MERCHANT_LOADING :
            return {...state, createLoading: action.payload};
        case CREATE_CHANNEL_MERCHANT_SUCCESS :
            return {...state, newChannelMerchant: action.payload.data};
        case SET_CHANNEL_ID :
            return {...state, channelId: action.payload};
        case CREATE_CHANNEL_MERCHANT_FAILURE :
            return {...state, errors: action.payload};
        default:
            return state;
    }
}